export const Models = [
  "ActivityLog",
  "Animal",
  "AnimalHuntingPlan",
  "AnimalHuntingPlanRealization",
  "Association",
  "Code",
  "Dog",
  "Hunter",
  "HunterHuntingCardOrder",
  "HuntingCard",
  "HuntingCardOrder",
  "HuntingExam",
  "HuntingExamCandidate",
  "HuntingGround",
  "Media",
  "Organization",
  "PersonalAccessToken",
  "Role",
  "StampOrder",
  "StampRange",
  "User",
  "WarehouseHuntingCard",
  "WarehouseStamp",
];

export const TranslatedModels = [
  "Log aktivnosti",
  "Životinja",
  "Godišnji plan",
  "Realiyacija godišnjeg plana",
  "Savez",
  "Kod",
  "Pas",
  "Lovac",
  "Zahtev lovca za lovnu kartu",
  "Lovna karta",
  "Zahtev za lovnu kartu",
  "Ispit",
  "Kandidat za ispit",
  "Lovište",
  "Media",
  "Korisnik lovišta / Agencija",
  "Lični token za pristup",
  "Rola",
  "Zahtev za markicu",
  "Brojevi markica",
  "Korisnik",
  "Magacin lovna karta",
  "Magacin markica",
];

export const defaultModelClassPrefix = "App\\Models\\";

const ucFirst = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const extractModelFromClassString = (
  classString: string,
  modelClassPrefix?: string,
): string => {
  modelClassPrefix ??= defaultModelClassPrefix;

  return classString.slice(modelClassPrefix.length);
};

export const translateModel = (
  classString?: string,
  ucfirst: boolean = true,
  modelClassPrefix?: string,
): string => {
  if (!classString) {
    return "-";
  }

  const model = extractModelFromClassString(classString, modelClassPrefix);

  const translated = TranslatedModels[Models.indexOf(model)] ?? "-";

  return ucfirst ? ucFirst(translated) : translated;
};

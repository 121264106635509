import CONSTANTS from "@/common/config/constants";
import { AnimalInputType } from "@/common/enums/animals";
import ROLES from "@/common/enums/roles";
import { AnimalHuntingPlanRecord } from "@/common/types/Animals";
import { DropdownData } from "@/common/types/Form";
import { IUser } from "@/common/types/User";
import QueryUtils from "@/common/utils/QueryUtils";
import Utils from "@/common/utils/Utils";
import Input from "@/components/forms/Input";
import MultiSelectDropdown from "@/components/forms/MultipleSelectDropdown";
import ProgressInput from "@/components/ProgressInput";

export const tableColumns = (
  onInputChange?: (
    animal_id: number,
    name: string,
    value: number,
    type: number,
  ) => void,
  isMonthFilled?: boolean,
  isSuperAdmin?: boolean,
  isRegularAdmin?: boolean,
  shouldHideValue?: boolean,
) => {
  return [
    {
      title: "Vrsta divljači",
      dataIndex: ["animal", "name"],
      key: ["animal", "name"],
    },
    {
      title: "Mužjak",
      dataIndex: "male_hunted",
      key: "male_hunted",
      render: ({
        animal,
        male_hunted,
        male_planned,
        male_percentage,
        male_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="male"
          total={male_planned}
          percent={male_percentage}
          percentValue={male_hunted}
          placeholder={`Fond divljači: ${male_remaining}`}
          value={
            shouldHideValue
              ? male_hunted ?? 0
              : isMonthFilled
                ? realization_for_month?.male
                : undefined
          }
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Ženka",
      dataIndex: "female_hunted",
      key: "female_hunted",
      render: ({
        animal,
        female_hunted,
        female_planned,
        female_percentage,
        female_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="female"
          total={female_planned}
          percent={female_percentage}
          percentValue={female_hunted}
          placeholder={`Fond divljači: ${female_remaining}`}
          value={
            shouldHideValue
              ? female_hunted ?? 0
              : isMonthFilled
                ? realization_for_month?.female
                : undefined
          }
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Podmladak",
      dataIndex: "offspring_hunted",
      key: "offspring_hunted",
      render: ({
        animal,
        offspring_hunted,
        offspring_planned,
        offspring_percentage,
        offspring_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="offspring"
          total={offspring_planned}
          percent={offspring_percentage}
          percentValue={offspring_hunted}
          placeholder={`Fond divljači: ${offspring_remaining}`}
          value={
            shouldHideValue
              ? offspring_hunted ?? 0
              : isMonthFilled
                ? realization_for_month?.offspring
                : undefined
          }
          hide={animal.input_type === AnimalInputType.Total}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
    {
      title: "Ukupno",
      dataIndex: "total_hunted",
      key: "total_hunted",
      render: ({
        animal,
        total_hunted,
        total_planned,
        total_percentage,
        total_remaining,
        realization_for_month,
      }: AnimalHuntingPlanRecord) => (
        <ProgressInput
          name="total"
          total={total_planned}
          percent={total_percentage}
          percentValue={total_hunted}
          placeholder={`Fond divljači: ${total_remaining}`}
          value={
            shouldHideValue
              ? total_hunted ?? 0
              : isMonthFilled
                ? realization_for_month?.total
                : undefined
          }
          hide={animal.input_type === AnimalInputType.Partial}
          disabled={
            isSuperAdmin ? false : isRegularAdmin ? true : isMonthFilled
          }
          onChange={(name: string, value: number) =>
            onInputChange?.(animal?.id, name, value, animal.input_type)
          }
        />
      ),
    },
  ];
};

export const filterFields = (
  organizations?: DropdownData[],
  huntingGrounds?: DropdownData[],
  seasons?: DropdownData[],
  query?: Record<string, string>,
  user?: IUser,
  isLoading?: boolean,
) => {
  const userHasUserRole = Utils.hasPermission(user?.roles, [ROLES.User]);

  return [
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik lovišta",
        name: "organization_ids",
        placeholder: "Izaberite korisnika lovišta",
        countablePlural: "korisnika lovišta",
        searchablePlural: "korisnike lovišta",
        value: "",
        options: organizations,
        disabled: isLoading,
        hide: userHasUserRole,
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovište",
        name: "hunting_ground_ids",
        placeholder: "Izaberite lovište",
        countablePlural: "lovišta",
        searchablePlural: "lovišta",
        value: "",
        options: QueryUtils.filterDropdownData(huntingGrounds, {
          organization_ids: userHasUserRole
            ? [user?.organization?.id]
            : query?.organization_ids,
        }),
        disabled: isLoading,
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovna sezona",
        name: "seasons",
        placeholder: "Izaberite lovnu sezonu",
        countablePlural: "lovnih sezona",
        searchablePlural: "lovne sezone",
        value: "",
        options: seasons,
        disabled: isLoading,
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Mesec",
        name: "months",
        placeholder: "Izaberite mesec",
        countablePlural: "meseca",
        searchablePlural: "mesece",
        value: "",
        options: CONSTANTS.monthObjects,
        disabled: isLoading,
      },
    },
  ];
};

export const filterQueries = {
  id: "",
  month: String(new Date().getUTCMonth()),
  months: "",
  season: "",
  seasons: "",
  organization_ids: "",
  hunting_ground_ids: "",
};

export const huntingPlanRecordFields = (
  huntingPlanRecord?: AnimalHuntingPlanRecord,
  isMonthFilled?: boolean,
) => [
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Mužjak - planirano ${huntingPlanRecord?.male_planned ?? 0}`,
      name: "male",
      placeholder: `Fond divljači: ${huntingPlanRecord?.male_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.male ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Ženka - planirano ${huntingPlanRecord?.female_planned ?? 0}`,
      name: "female",
      placeholder: `Fond divljači: ${huntingPlanRecord?.female_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.female ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Podmladak - planirano ${huntingPlanRecord?.offspring_planned ?? 0}`,
      name: "offspring",
      placeholder: `Fond divljači: ${huntingPlanRecord?.offspring_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.offspring ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Total,
    },
  },
  {
    controlType: Input,
    className: "col-span-12 md:col-span-6",
    options: {
      label: `Ukupno - planirano - ${huntingPlanRecord?.total_planned ?? 0}`,
      name: "total",
      placeholder: `Fond divljači: ${huntingPlanRecord?.total_remaining}`,
      value: isMonthFilled
        ? huntingPlanRecord?.realization_for_month?.total ?? ""
        : "",
      hide: huntingPlanRecord?.animal?.input_type === AnimalInputType?.Partial,
    },
  },
];

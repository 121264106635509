import { DropdownData } from "@/common/types/Form";
import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  asTableColumns,
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-reports-fields";
import { HuntingCardReport } from "@/common/types/Reports";
import { ReportsService } from "@/common/API/ReportsService";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { useAuth } from "@/common/context/AuthProvider";
import Card from "@/components/Card";
import { StatisticsData } from "@/common/types/Reports";

const HuntingCardReportsList = () => {
  const [data, setData] = useState<any>();
  const [statisticsData, setStatisticsData] = useState<StatisticsData>();
  const [seasons, setSeasons] = useState<DropdownData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { loginData, isAdmin, isSuperAdminOrModerator, isInspector } =
    useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations } = useDropdownData({ hasAgency: true, query });

  const getHuntingCardReport = async () => {
    setIsLoading(true);

    const response = await ReportsService.all_hunting_cards(
      Utils.formatQueryString(query),
    );

    const availableSeasons: DropdownData[] =
      response?.data?.data?.available_seasons.map((season: string) => ({
        name: season,
        id: season,
      }));

    if (query?.season && query?.season === "") {
      const season = availableSeasons?.[0]?.id ?? "";

      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        season: prevState?.season || String(season),
      }));
    }

    setData(response?.data?.data?.report);
    setSeasons(availableSeasons);
    setIsLoading(false);
  };

  const getHuntingCardStatistics = async () => {
    if (!isSuperAdminOrModerator) return;
    setIsLoading(true);

    const response = await ReportsService.get_statistics(
      Utils.formatQueryString(query),
    );
    setStatisticsData(response?.data?.data);
    setIsLoading(false);
  };

  useEffect(() => {
    getHuntingCardStatistics();
    getHuntingCardReport();
    isAdmin &&
      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        association_id: String(loginData?.data?.association?.id),
      }));
  }, []);

  useEffect(() => {
    getHuntingCardStatistics();
    getHuntingCardReport();
  }, [query]);

  const statisticsCards = () => {
    return (
      isSuperAdminOrModerator && (
        <div className="gap-4 pb-4 md:grid md:grid-cols-4">
          <div className="mb-4 flex-1 md:col-span-4 md:mb-0">
            <Card
              titleClass="h-6"
              title="Broj položenih lovačkih ispita"
              content={
                statisticsData?.report?.exam_candidates_count?.length === 0 ? (
                  <span className="text-sm font-medium">
                    Nema zabeleženih podataka
                  </span>
                ) : (
                  <div className="grid h-auto grid-cols-4 gap-8">
                    {statisticsData?.report?.exam_candidates_count?.map(
                      (el, index) => (
                        <div className="col-span-1 w-full" key={index}>
                          <span className="text-sm uppercase text-gray-400">
                            {el?.year}:
                          </span>
                          <h2 className="text-xl font-medium">
                            {el?.candidates_count}
                          </h2>
                        </div>
                      ),
                    )}
                  </div>
                )
              }
              isLoading={isLoading}
            />
          </div>
          <div className="mb-4 flex-1 md:mb-0">
            <Card
              titleClass="h-6"
              title="Ukupan broj korisnika lovišta"
              content={
                <div className="h-6">
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.organization_count}
                  </h2>
                </div>
              }
              isLoading={isLoading}
            />
          </div>
          <div className="mb-4 flex-1 md:mb-0">
            <Card
              titleClass="h-6"
              title="Ukupan broj odobrenih lovnih karti"
              content={
                <div className="h-6">
                  <h2 className="text-xl font-medium">
                    {statisticsData?.total_cards}
                  </h2>
                </div>
              }
              isLoading={isLoading}
            />
          </div>
          <div className="mb-4 flex-1 md:mb-0">
            <Card
              titleClass="h-6"
              title="Ukupan broj lovišta"
              content={
                <div className="h-6">
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.hunting_ground_count}
                  </h2>
                </div>
              }
              isLoading={isLoading}
            />
          </div>
          <div className="flex-1">
            <Card
              titleClass="h-6"
              title="Ukupan broj izdatih markica"
              content={
                <div className="h-6">
                  <h2 className="text-xl font-medium">
                    {statisticsData?.report?.delivered_stamp_count}
                  </h2>
                </div>
              }
              isLoading={isLoading}
            />
          </div>
        </div>
      )
    );
  };
  const associationTable = () => {
    return (
      <div className="mb-4">
        <Table
          data={{ data: data?.associations }}
          columns={asTableColumns(data)}
          query={query}
          hideActions={true}
          isFetching={isLoading}
          hasFixedWidthColumns={true}
        />
      </div>
    );
  };
  return (
    <PageLayout title={`Izveštaji za lovne karte`}>
      {statisticsCards()}
      <Table
        data={{ data: data?.organizations }}
        columns={tableColumns(data)}
        filterFields={filterFields(associations, seasons, loginData?.data)}
        filterQueries={filterQueries}
        hidePagination={true}
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        customComponent={
          isSuperAdminOrModerator || isInspector ? associationTable() : null
        }
        hasFixedWidthColumns={true}
        isFetching={isLoading}
      />
    </PageLayout>
  );
};

export default HuntingCardReportsList;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "@/components/table/Table";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-grounds-fields";
import { HuntingGroundResponse } from "@/common/types/HuntingGrounds";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import useDropdownData from "@/common/hooks/useDropdownData";

const HuntingGroundsList = () => {
  const [data, setData] = useState<HuntingGroundResponse>();
  const [columns, setColumns] = useState<any>([]);
  const [isFetching, setIsFetching] = useState(false);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { organizations } = useDropdownData({ hasAgency: true, query });

  const navigate = useNavigate();
  const { loginData, isInspector } = useAuth();

  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);

  const canEditDocument = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Moderator,
  ]);

  const getHuntingGroundsData = async () => {
    try {
      setIsFetching(true);
      const res = await HuntingGroundsService.all(
        Utils.formatQueryString(query),
      );
      setData(res?.data);
    } finally {
      setIsFetching(false);
    }
  };

  const onDelete = async (id?: string) => {
    await HuntingGroundsService.delete(id);
    getHuntingGroundsData();
  };

  const onDeleteDocument = async (id?: string) => {
    await HuntingGroundsService.deleteBasis(id);
    getHuntingGroundsData();
  };

  const onUploadDocument = async (id?: string, documentData?: any) => {
    const formData = new FormData();
    formData.append("basis_document", documentData);
    formData.append("_method", "PATCH");

    await HuntingGroundsService.addBasis(id, formData);
    getHuntingGroundsData();
  };

  useEffect(() => {
    setColumns(
      tableColumns(
        onUploadDocument,
        onDeleteDocument,
        canEditDocument,
        isInspector,
      ),
    );
  }, []);

  useEffect(() => {
    getHuntingGroundsData();
  }, [query]);

  return (
    <PageLayout title="Lovišta" showCreateButton={isAdmin}>
      <Table
        data={data}
        columns={columns}
        filterFields={filterFields(organizations)}
        filterQueries={filterQueries}
        onEdit={(item) => navigate(`/hunting-grounds/${item?.id}`)}
        onDelete={(id) => onDelete(id)}
        hidePrint={true}
        hideEdit={Utils.hasPermission(loginData?.data?.roles, [
          ROLES.Inspector,
        ])}
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Moderator,
          ])
        }
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};

export default HuntingGroundsList;

import DocumentLink from "@/components/DocumentLink";
import CONSTANTS from "../config/constants";
import { Role } from "../types/User";
import Text from "@/components/Text";
import config from "../config/config";

const Utils = {
  JSON: (json: any) => {
    return (
      <pre className="overflow-hidden rounded-md bg-gray-800 p-3 text-yellow-300 shadow-2xl">
        {JSON.stringify(json, null, 2)}
      </pre>
    );
  },
  getObjectKeyValue: (object: any) => {
    const keys = Object.keys(object).filter(
      (k) => typeof object[k] === "number",
    );
    const values = keys.map((k) => object[k]);

    return [keys, values];
  },
  htmlToText(htmlString: string) {
    let span = document.createElement("span");
    span.innerHTML = htmlString;
    return span.textContent || span.innerText;
  },
  isDateTime: (value: any) => {
    if (typeof value !== "string") {
      return false;
    }

    const date = new Date(value);
    return date instanceof Date && !isNaN(date.getTime());
  },
  convertTime(inputTime: string) {
    const date = new Date(inputTime);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  },
  convertDateTime(inputTime: string) {
    const date = new Date(inputTime);
    const seconds = ("0" + date.getSeconds()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  },
  hasPermission(userRoles?: Role[], allowedRoles?: number[]) {
    return userRoles?.some((role: Role) => allowedRoles?.includes(role?.id));
  },
  getMonthString(dateString: string) {
    const date = new Date(dateString);
    const monthIndex = date.getUTCMonth();
    const monthName = CONSTANTS.months[monthIndex];
    const dayName = date.getUTCDate();

    return `${dayName}. ${monthName}`;
  },
  formatQueryString(obj: Record<string, any>) {
    const queryString = Object.keys(obj)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`,
      )
      .join("&");

    return queryString;
  },
  formatMultipleOptionsQueryString(obj: Record<string, any>) {
    const queryString = Object.keys(obj)
      .map((key) => {
        if (Array.isArray(obj[key])) {
          return obj[key]
            .map(
              (value: any) =>
                `${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`,
            )
            .join("&");
        } else {
          return `${encodeURIComponent(key)}=${encodeURIComponent(obj[key].toString())}`;
        }
      })
      .join("&");
    return queryString;
  },
  debounce(callback: () => void, delay: number = 300) {
    let timeout: NodeJS.Timeout;

    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback();
      }, delay);
    };
  },
  formatNumber(number?: number) {
    return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  openDocument(documentLink?: string) {
    const newWindow = window.open(
      documentLink,
      "_blank",
      "noopener,noreferrer",
    );
    if (newWindow) newWindow.opener = null;
  },
  isValidJSON(value: any): boolean {
    if (!(typeof value == "string")) {
      return false;
    }

    try {
      JSON.parse(value);
    } catch (exception: any) {
      return false;
    }

    return true;
  },
  getRenderValue: (value?: any): JSX.Element => {
    if (value === undefined) {
      return <Text variant="table" children="-" />;
    }

    if (Utils.isValidJSON(value)) {
      const data = JSON.parse(value);

      if (Boolean(data?.url)) {
        return (
          <DocumentLink
            url={`${config.storageUrl}/${String(data?.url)}`}
            title={String(data?.name)}
            hideDelete={true}
          />
        );
      }

      return (
        <Text
          variant="table"
          children={data?.name ? String(data?.name) : Utils.JSON(data)}
        />
      );
    }

    if (value?.constructor === Object) {
      if (Boolean(value?.url)) {
        return (
          <DocumentLink
            url={`${config.storageUrl}/${String(value?.url)}`}
            title={String(value?.name)}
            hideDelete={true}
          />
        );
      }

      return <Text variant="table" children={String(value?.name)} />;
    }

    if (Utils.isDateTime(value)) {
      return <Text variant="table" children={Utils.convertDateTime(value)} />;
    }

    return <Text variant="table" children={String(value)} />;
  },
};

export default Utils;

import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./activity-logs-fields";
import { useQuery } from "@/common/hooks/useQuery";
import { ActivityLogsService } from "@/common/API/ActivityLogsService";
import Utils from "@/common/utils/Utils";
import { useNavigate } from "react-router";
import { ActivityLog } from "@/common/types/ActivityLog";
import useDropdownData from "@/common/hooks/useDropdownData";

const ActivityLogsList = () => {
  const [data, setData] = useState<ActivityLog[]>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { organizations, associations } = useDropdownData({
    hasAgency: true,
    query,
  });
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const getActivityLogs = async () => {
    try {
      setIsFetching(true);
      const res = await ActivityLogsService.all(Utils.formatQueryString(query));

      setData(res?.data);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getActivityLogs();
  }, [query]);

  return (
    <PageLayout title="Log aktivnosti">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(organizations, associations)}
        filterQueries={filterQueries}
        hidePrint={true}
        hideEdit={false}
        hideDelete={true}
        onEdit={(item) => navigate(`/activity-logs/${item?.id}`)}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};

export default ActivityLogsList;

import { createContext, PropsWithChildren, useContext, useState } from "react";
import storage from "../Storage";
import { IUser } from "../types/User";
import Utils from "../utils/Utils";
import { useSidebar } from "./SidebarProvider";
import ROLES from "../enums/roles";

interface LoginContextType {
  isLoggedIn: boolean;
  loginData: ILoginResponse | null;
  isSuperAdmin: boolean | undefined;
  isModerator: boolean | undefined;
  isAdmin: boolean | undefined;
  isUser: boolean | undefined;
  isAgency: boolean | undefined;
  isInspector: boolean | undefined;
  isRanger: boolean | undefined;
  isExaminer: boolean | undefined;
  isSuperAdminOrModerator: boolean | undefined;
  isUserOrAgency: boolean | undefined;
  login: (loginData: ILoginResponse) => void;
  logout: () => void;
}

export interface ILoginResponse {
  message: string;
  token: string;
  expires_in: number;
  data: IUser;
}

const LoginContext = createContext<LoginContextType | null>(null);

export const useAuth = () => {
  const context = useContext(LoginContext);
  if (!context) {
    throw new Error("useLogin must be used within a LoginProvider");
  }
  return context;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loginData, setLoginData] = useState<ILoginResponse | null>(null);
  const { resetSidebar } = useSidebar();

  const userRoles = loginData?.data?.roles ?? [];

  const isSuperAdmin = Utils.hasPermission(userRoles, [ROLES.Superadmin]);
  const isModerator = Utils.hasPermission(userRoles, [ROLES.Moderator]);
  const isAdmin = Utils.hasPermission(userRoles, [ROLES.Admin]);
  const isUser = Utils.hasPermission(userRoles, [ROLES.User]);
  const isAgency = Utils.hasPermission(userRoles, [ROLES.Agency]);
  const isInspector = Utils.hasPermission(userRoles, [ROLES.Inspector]);
  const isRanger = Utils.hasPermission(userRoles, [ROLES.Ranger]);
  const isExaminer = Utils.hasPermission(userRoles, [ROLES.Examiner]);

  const login = (data: ILoginResponse) => {
    setIsLoggedIn(true);
    setLoginData(data);
  };

  const logout = () => {
    setIsLoggedIn(false);
    setLoginData(null);
    storage.remove("user");
    storage.remove("selectedRows");
    storage.remove("all");

    resetSidebar();
  };

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        loginData: loginData,
        isSuperAdmin: isSuperAdmin,
        isModerator: isModerator,
        isAdmin: isAdmin,
        isUser: isUser || isAgency,
        isAgency: isAgency,
        isInspector: isInspector,
        isRanger: isRanger,
        isExaminer: isExaminer,
        isSuperAdminOrModerator: isSuperAdmin || isModerator,
        isUserOrAgency: isUser || isAgency,
        login: login,
        logout: logout,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

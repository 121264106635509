import { StampType } from "@/common/enums/stamps";
import { DropdownData } from "@/common/types/Form";
import { WarehouseStamp } from "@/common/types/Warehouse";
import Utils from "@/common/utils/Utils";
import ConfirmationPopover from "@/components/ConfirmationPopover";
import Dropdown from "@/components/forms/Dropdown";
import Input from "@/components/forms/Input";
import TableActionButton from "@/components/table/TableActionButton";
import Text from "@/components/Text";
import { AiOutlineEdit } from "react-icons/ai";
import { CgTrash } from "react-icons/cg";

export const tableColumns = ({
  hideActions,
  onEdit,
  onDelete,
}: {
  hideActions: boolean;
  onEdit: (data: WarehouseStamp) => void;
  onDelete: (id: string) => void;
}) => {
  return [
    {
      title: "Tip",
      dataIndex: "stamp_type",
      key: "stamp_type",
      render: ({ stamp_type }: { stamp_type: number }) => (
        <Text variant="table">
          {stamp_type === StampType.Twopart ? "Dvodelna" : "Trodelna"}
        </Text>
      ),
    },
    {
      title: "Markice od br.",
      dataIndex: "from_number",
      key: "from_number",
      render: ({
        short_name,
        from_number,
      }: {
        short_name: string;
        from_number: number;
      }) => <Text variant="table">{`${short_name ?? ""} ${from_number}`}</Text>,
    },
    {
      title: "Markice do br.",
      dataIndex: "to_number",
      key: "to_number",
      render: ({
        short_name,
        to_number,
      }: {
        short_name: string;
        to_number: number;
      }) => <Text variant="table">{`${short_name ?? ""} ${to_number}`}</Text>,
    },
    {
      title: "Raspoloživo",
      dataIndex: "available_stamps",
      key: "available_stamps",
      render: ({ available_stamps }: { available_stamps: number }) => (
        <Text variant="table">
          {available_stamps < 0 ? 0 : available_stamps}
        </Text>
      ),
    },
    {
      title: "Sezona",
      dataIndex: "season",
      key: "season",
    },
    {
      title: "Datum dodavanja",
      dataIndex: "created_at",
      key: "created_at",
      render: ({ created_at }: { created_at: string }) => (
        <Text variant="table">{Utils.convertTime(created_at)}</Text>
      ),
    },
    {
      title: "Akcije",
      dataIndex: "is_used",
      key: "is_used",
      render: (warehouseStamp: WarehouseStamp) => (
        <div className="flex flex-row items-center space-x-2 self-center">
          {!hideActions && (
            <TableActionButton
              icon={<AiOutlineEdit size={16} />}
              tooltipText="Detalji"
              onClick={() => onEdit?.(warehouseStamp)}
            />
          )}
          {!hideActions && !warehouseStamp?.is_used && (
            <ConfirmationPopover
              title="Da li ste sigurni? Stavka će biti obrisana."
              onDelete={() => onDelete?.(warehouseStamp?.id)}
              button={
                <div>
                  <CgTrash
                    size={15}
                    className="cursor-pointer self-center text-gray-400 transition-all duration-300 hover:text-red-500"
                  />
                </div>
              }
            />
          )}
        </div>
      ),
    },
  ];
};

export const filterFields = (associations?: DropdownData[]) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovački savez",
        name: "association_id",
        placeholder: "Izaberite lovački savez",
        value: "",
        required: false,
        options: associations ?? [],
      },
    },
  ];
};

export const filterQueries = {
  association_id: "",
};

export const warehouseStampsAddFields = (
  associations?: DropdownData[],
  animals?: DropdownData[],
  stamp_types?: DropdownData[],
  getStamps?: (animal_id: string) => void,
  getLastStampNumber?: (stamp_type: string) => void,
  associationId?: string,
  lastAvailableStampNumber?: number,
) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Lovački savez",
        name: "association_id",
        placeholder: "Izaberite lovački savez",
        value: associationId ?? "",
        required: true,
        options: associations ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Vrsta divljači",
        name: "animal_id",
        placeholder: "Izaberite vrstu divljači",
        value: "",
        required: true,
        callback: (animal_id: string) => getStamps?.(animal_id),
        options: animals ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Tip markice",
        name: "stamp_type",
        placeholder: "Izaberite tip markice",
        value: "",
        required: true,
        callback: (stamp_type: string) => getLastStampNumber?.(stamp_type),
        options: stamp_types ?? [],
        disabled: stamp_types?.length === 0,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-4",
      options: {
        label: `Markice od broja ${lastAvailableStampNumber ? `- ${lastAvailableStampNumber}` : ""}`,
        name: "from_number",
        placeholder: "Unesite broj",
        value: "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Markice do broja",
        name: "to_number",
        placeholder: "Unesite broj",
        value: "",
        required: true,
      },
    },
  ];
};

export const warehouseStampsEditFields = (
  associations?: DropdownData[],
  animals?: DropdownData[],
  stamp_types?: DropdownData[],
  getStamps?: (animal_id: string) => void,
  getLastStampNumber?: (stamp_type: string) => void,
  editStamp?: WarehouseStamp | null,
  lastAvailableStampNumber?: number,
) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Lovački savez",
        name: "association_id",
        placeholder: "Izaberite lovački savez",
        value: String(editStamp?.association?.id) ?? "",
        required: true,
        options: associations ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Vrsta divljači",
        name: "animal_id",
        placeholder: "Izaberite vrstu divljači",
        value: String(editStamp?.animal_id) ?? "",
        required: true,
        callback: (animal_id: string) => getStamps?.(animal_id),
        options: animals ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-4",
      options: {
        label: "Tip markice",
        name: "stamp_type",
        placeholder: "Izaberite tip markice",
        value: String(editStamp?.stamp_type) ?? "",
        required: true,
        options: stamp_types ?? [],
        disabled: stamp_types?.length === 0,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-4",
      options: {
        label: editStamp
          ? `Markice od broja - ${editStamp.from_number}`
          : "Markice od broja",
        name: "from_number",
        placeholder: "Unesite broj",
        value: String(editStamp?.from_number) ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-4",
      options: {
        label: editStamp
          ? `Markice do broja - ${editStamp.to_number}`
          : "Markice do broja",
        name: "to_number",
        placeholder: "Unesite broj",
        value: String(editStamp?.to_number) ?? "",
        required: true,
      },
    },
  ];
};

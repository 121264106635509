import FormFactory from "@/components/forms/FormFactory";
import PageLayout from "@/components/layout/PageLayout";
import { useEffect, useRef, useState } from "react";
import { activityLogFields, detailsTableColumns } from "./activity-logs-fields";
import { ActivityLogsService } from "@/common/API/ActivityLogsService";
import { FormikProps } from "formik";
import { useParams } from "react-router";
import { FormField } from "@/common/types/Form";
import {
  ActivityLog,
  filterAttributes,
  Properties,
  PropertyData,
} from "@/common/types/ActivityLog";
import Table from "@/components/table/Table";
import { translateAttribute } from "./Attributes";

const ActivityLogEdit = () => {
  const [tableData, setTableData] = useState<any>([]);
  const formRef = useRef<FormikProps<any>>(null);
  const { id } = useParams();
  const [logEvenet, setLogEvent] = useState<string | undefined>("");

  const [formFields, setFormFields] = useState<FormField[]>([]);

  const getActivityLog = async () => {
    const res = await ActivityLogsService.one(id);
    const activityLog: ActivityLog = res?.data?.data;
    setLogEvent(activityLog?.event);

    setFormFields(activityLogFields(activityLog));

    if (formRef?.current) {
      formRef.current.setValues(activityLog);
    }

    return activityLog;
  };

  const prepareTableData = (activityLog: ActivityLog) => {
    const properties: Properties = activityLog?.properties[0];
    const updated = filterAttributes(Object.entries(properties.updated));
    const original = filterAttributes(Object.entries(properties.original));

    const tableData: PropertyData[] = original.map(
      ([key, property], index) => ({
        column: translateAttribute(key),
        original: property ?? "-",
        updated: updated[index][1] ?? "-",
      }),
    );

    setTableData({ data: tableData });
  };

  useEffect(() => {
    const awaitActivityLog = async () => {
      const activityLog = await getActivityLog();

      if (activityLog) prepareTableData(activityLog);
    };

    awaitActivityLog();
  }, []);

  return (
    <PageLayout title="Log aktivnisti" backUrl="./../">
      <FormFactory formFields={formFields} formRef={formRef} />
      {logEvenet !== "deleted" && (
        <Table
          data={tableData}
          columns={detailsTableColumns}
          hideActions={true}
        />
      )}
    </PageLayout>
  );
};

export default ActivityLogEdit;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Table from "@/components/table/Table";
import { StampsService } from "@/common/API/StampsService";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./stamp-orders-fields";
import { IStamp } from "@/common/types/Stamps";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import useDropdownData from "@/common/hooks/useDropdownData";
import { DropdownData } from "@/common/types/Form";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";

const StampOrdersList = () => {
  const { loginData } = useAuth();

  const [data, setData] = useState<IStamp[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [huntingGrounds, setHuntingGrouds] = useState<DropdownData[]>([]);
  const [huntingGroundId, setHuntingGroundId] = useState<any>(undefined);
  const hasOrganization = Boolean(loginData?.data?.organization?.id);
  const hasAssociation = Boolean(loginData?.data?.association?.id);

  const navigate = useNavigate();
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const isSingleHuntingGround =
    query?.hunting_ground_ids?.length === 1 &&
    typeof query?.hunting_ground_ids === "object";

  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });

  const getHuntingGrounds = async () => {
    if (
      !Utils.hasPermission(loginData?.data?.roles, [
        ROLES.Superadmin,
        ROLES.Moderator,
        ROLES.Admin,
        ROLES.User,
        ROLES.Inspector,
      ])
    ) {
      return;
    }

    const associationId = hasAssociation
      ? String(loginData?.data?.association?.id)
      : undefined;

    const organizationId = hasOrganization
      ? String(loginData?.data?.organization?.id)
      : undefined;

    const response = await HuntingGroundsService.all_dropdown(
      organizationId,
      associationId,
    );

    const huntingGrounds = response?.data?.data;

    setHuntingGrouds(huntingGrounds);

    if (isSingleHuntingGround || huntingGrounds?.length === 1) {
      setHuntingGroundId(huntingGrounds[0]?.id);
    }
  };

  const getStampOrders = async () => {
    try {
      setIsFetching(true);
      const res = await StampsService.all(Utils.formatQueryString(query));
      setData(res?.data);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getStampOrders();
    getHuntingGrounds();
  }, [query]);

  const onDeleteStampOrder = async (id?: string) => {
    await StampsService.delete(id);
    getStampOrders();
  };

  return (
    <PageLayout title="Zahtevi za markice">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(
          // Utils.hasPermission(loginData?.data?.roles, [ROLES.User]),
          associations,
          huntingGrounds,
          hasAssociation,
          query,
          loginData?.data,
        )}
        filterQueries={filterQueries}
        onEdit={(item) => navigate(`/stamp-orders/${item?.id}`)}
        onDelete={(id) => onDeleteStampOrder(id)}
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
          ])
        }
        hidePrint={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};

export default StampOrdersList;

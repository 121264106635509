import { Causer } from "./User";

export type Property = {
  key: any;
};

export type Properties = {
  updated: Property[] | any[];
  original: Property[] | any[];
};

export type ActivityLog = {
  id: number;
  event?: string;
  causer_type?: string;
  causer_id?: number | null;
  subject_type?: string;
  subject_id?: number | null;
  description?: string;
  created_at: string;
  properties: Properties[];
  causer?: Causer | null;
  subject?: any;
};

export type PropertyData = {
  column: string;
  original: string;
  updated: string;
};

export const filterAttributes = (attributes: [string, any][]) => {
  return attributes.filter(([attribute]) => attribute !== "updated_at");
};

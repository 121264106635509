import Utils from "@/common/utils/Utils";
import Input from "@/components/forms/Input";
import { HuntingCardType } from "@/common/enums/hunting";
import StatusTag from "@/components/StatusTag";
import Text from "@/components/Text";
import { DropdownData } from "@/common/types/Form";
import DropdownSearch from "@/components/forms/DropdownSearch";
import MultiSelectDropdown from "@/components/forms/MultipleSelectDropdown";
import Dropdown from "@/components/forms/Dropdown";
import { IUser } from "@/common/types/User";

export const tableColumns = [
  {
    title: "Broj Lovne Karte",
    dataIndex: "card_number",
    key: "card_number",
    sortOptions: {
      sortDirection: "",
      sortParam: "card_number",
    },
  },
  {
    title: "Ime",
    dataIndex: "hunter.name",
    key: ["hunter", "name"],
    sortOptions: {
      sortDirection: "",
      sortParam: "hunter.name",
    },
  },
  {
    title: "Lični broj",
    dataIndex: ["hunter", "unique_identifier"],
    key: ["hunter", "unique_identifier"],
  },
  {
    title: "Korisnik lovišta",
    dataIndex: "organization.name",
    key: ["organization", "name"],
    sortOptions: {
      sortDirection: "",
      sortParam: "organization.name",
    },
  },
  {
    title: "Lovna sezona",
    dataIndex: "season",
    key: "season",
    sortOptions: {
      sortDirection: "",
      sortParam: "season",
    },
  },
  {
    title: "Datum izdavanja",
    dataIndex: "created_at",
    key: "created_at",
    render: ({ created_at }: { created_at: string }) => (
      <Text variant="table">{Utils.convertTime(created_at)}</Text>
    ),
    sortOptions: {
      sortDirection: "",
      sortParam: "created_at",
    },
  },
  {
    title: "Tip",
    dataIndex: "type",
    key: "type",
    render: ({ type }: { type: number }) => {
      switch (type) {
        case HuntingCardType.Tourist:
          return <StatusTag type="in-progress" title="Turistička" />;
        case HuntingCardType.Member:
          return <StatusTag type="accepted" title="Članska" />;
      }
    },
    sortOptions: {
      sortDirection: "",
      sortParam: "type",
    },
  },
];

export const filterFields = (
  organizations?: DropdownData[],
  seasons?: DropdownData[],
) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Ime i prezime",
        name: "name",
        placeholder: "Pretraži po imenu i prezimenu",
        focus: true,
        value: "",
      },
    },
    {
      controlType: Input,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Broj lovne karte",
        name: "card_number",
        placeholder: "Pretraži po broju lovne karte",
        focus: true,
        value: "",
      },
    },
    {
      controlType: Input,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Matični broj",
        name: "unique_identifier",
        placeholder: "Pretraži po matičnom broju",
        value: "",
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Korisnik lovišta",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta",
        options: organizations ?? [],
        value: "",
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Sezona",
        name: "season",
        placeholder: "Izaberi sezonu",
        options: seasons ?? [],
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  name: "",
  card_number: "",
  unique_identifier: "",
  organization_id: "",
  season: "",
  sort: "",
  order: "",
};

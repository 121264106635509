import Table from "@/components/table/Table";
import { useEffect, useState, useRef } from "react";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./hunting-cards-fields";
import { HuntingCardService } from "@/common/API/HuntingCardService";
import {
  HuntingCardsResponse,
  IHuntingCard,
} from "@/common/types/HuntingCards";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import useDropdownData from "@/common/hooks/useDropdownData";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";

import Modal from "@/components/Modal";
import { FormikProps } from "formik";
import FormFactory from "@/components/forms/FormFactory";
import { createHunterFields } from "../hunting-card-orders/hunter-fields";
import { HunterData } from "@/common/types/HuntingOrders";
import { UpdateHunterSchema } from "@/common/schemas/Hunting";
import { Button } from "@/components/ui/button";
import { IoPrintOutline } from "react-icons/io5";
import { DropdownData } from "@/common/types/Form";
import QueryUtils from "@/common/utils/QueryUtils";

const HuntingCardsList = () => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [hunter, setHunter] = useState<HunterData>();
  const [seasons, setSeasons] = useState<DropdownData[]>([]);
  const [huntingCardOrder, setHuntingCardOrder] = useState<string>();
  const hunterEditFormRef = useRef<FormikProps<HunterData>>(null);
  const [isFetching, setIsFetching] = useState(false);
  const onEditHunter = async (formData?: HunterData) => {
    await HuntingCardOrdersService.updateHunter(
      huntingCardOrder,
      hunter?.id,
      formData,
    );

    setOpenEditModal(false);
    getHuntingCards();
  };

  const [data, setData] = useState<HuntingCardsResponse | []>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { organizations } = useDropdownData({ hasAgency: true, query });
  const { loginData } = useAuth();

  const setEditablePrintableBoolean = (data: any) => {
    return data.map((item: any) => {
      if (
        Utils.hasPermission(loginData?.data?.roles, [
          ROLES.Superadmin,
          ROLES.Moderator,
        ])
      ) {
        return {
          ...item,
          canEdit: true,
          canPrint: true,
        };
      } else if (
        Utils.hasPermission(loginData?.data?.roles, [ROLES.Admin]) &&
        item?.organization?.association_id === loginData?.data?.association?.id
      ) {
        return {
          ...item,
          canEdit: true,
          canPrint: true,
        };
      } else if (
        Utils.hasPermission(loginData?.data?.roles, [ROLES.User]) &&
        item?.organization?.id === loginData?.data?.organization?.id
      ) {
        return {
          ...item,
          canEdit: false,
          canPrint: true,
        };
      } else if (
        Utils.hasPermission(loginData?.data?.roles, [ROLES.Inspector])
      ) {
        return {
          ...item,
          canEdit: false,
          canPrint: true,
        };
      }
      return {
        ...item,
        canEdit: false,
        canPrint: false,
      };
    });
  };

  const getHuntingCards = async () => {
    try {
      setIsFetching(true);
      const response = await HuntingCardService.all(
        Utils.formatQueryString(query),
      );

      const data = {
        data: setEditablePrintableBoolean(response?.data?.data?.hunting_cards),
        pagination: response?.data?.pagination,
      };
      setData(data);
      setSeasons(
        QueryUtils.formatAvailableSeasons(
          response?.data?.data?.available_seasons,
        ),
      );
    } finally {
      setIsFetching(false);
    }
  };

  const onPrint = async (huntingCard: IHuntingCard) => {
    const response = await HuntingCardService.pdf(huntingCard.id);

    Utils.openDocument(response?.data?.data);
  };

  const onExport = async () => {
    const response = await HuntingCardService.export(
      Utils.formatQueryString(query),
    );

    Utils.openDocument(response?.data?.data);
  };

  useEffect(() => {
    getHuntingCards();
  }, [query]);

  return (
    <PageLayout
      title="Lovne karte"
      buttons={
        <>
          {(query.season || query.organization_id) && (
            <Button
              type="button"
              variant="secondary"
              onClick={onExport}
              hide={
                !Utils.hasPermission(loginData?.data?.roles, [
                  ROLES.Superadmin,
                  ROLES.Admin,
                  ROLES.Moderator,
                ])
              }
            >
              Eksportuj
              <IoPrintOutline className="ml-3" />
            </Button>
          )}
        </>
      }
    >
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(organizations, seasons)}
        filterQueries={filterQueries}
        hideActions={false}
        hideDelete={true}
        onPrint={(huntingCard) => onPrint(huntingCard)}
        onEdit={(data) => {
          setHunter({
            ...data.hunter,
            hunting_card_type: String(data.type),
          });
          setHuntingCardOrder(String(data?.hunting_card_order?.id));
          setOpenEditModal(true);
        }}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        isFetching={isFetching}
      />
      <Modal
        open={openEditModal}
        setOpen={setOpenEditModal}
        headerTitle="Izmeni lovca"
        buttonTitle="Izmeni"
        onSubmit={() => hunterEditFormRef?.current?.handleSubmit()}
        content={
          <FormFactory
            formFields={createHunterFields(hunter, false)}
            onSubmit={(formData) => onEditHunter(formData)}
            formRef={hunterEditFormRef}
            validationSchema={UpdateHunterSchema}
          />
        }
      />
    </PageLayout>
  );
};

export default HuntingCardsList;

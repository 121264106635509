import CONSTANTS from "@/common/config/constants";
import {
  ActivityLog,
  filterAttributes,
  Properties,
} from "@/common/types/ActivityLog";
import { DropdownData, FormField } from "@/common/types/Form";
import Utils from "@/common/utils/Utils";
import DatePicker from "@/components/forms/DatePicker";
import Dropdown from "@/components/forms/Dropdown";
import Input from "@/components/forms/Input";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import { translateAttribute } from "./Attributes";
import { translateModel } from "./Models";
import DropdownSearch from "@/components/forms/DropdownSearch";

export const tableColumns = [
  {
    title: "Ime Korisnika",
    dataIndex: "log",
    key: "log",
    render: (log: ActivityLog) => {
      return <Text variant="table">{log?.causer?.name ?? "-"}</Text>;
    },
  },
  {
    title: "Role Korisnika",
    dataIndex: "log",
    key: "log",
    render: (log: ActivityLog): any => {
      return (
        <div className="flex flex-row items-center space-x-1">
          {log?.causer?.roles?.map((role, index) => (
            <Tag
              key={index}
              title={role?.name}
              className={`${role?.id === 8 ? "bg-amber-100 text-amber-500" : "bg-green-100 text-green-500"}`}
            />
          )) ?? "-"}
        </div>
      );
    },
  },
  {
    title: "Tip Izmene",
    dataIndex: "event_translation",
    key: "event_translation",
  },
  {
    title: "Izmene",
    dataIndex: "log",
    key: "log",
    render: (log: ActivityLog) => {
      const properties: Properties = log.properties[0];
      const fields = filterAttributes(Object.entries(properties?.original));

      return (
        <div className="fap-1 flex flex-row flex-wrap items-center gap-1">
          {fields.length > 3 ? (
            <Tag
              key={fields[0][0]}
              title={"Više aktivnosti"}
              className={"bg-orange-100 text-orange-500"}
            />
          ) : (
            fields.map(([key]) => (
              <Tag
                key={key}
                title={translateAttribute(key)}
                className={"bg-orange-100 text-orange-500"}
              />
            ))
          )}
        </div>
      );
    },
  },
  {
    title: "Vreme Izmene",
    dataIndex: "created_at",
    key: "created_at",
    render: ({ created_at }: { created_at: string }) => (
      <Text variant="table">{Utils.convertDateTime(created_at)}</Text>
    ),
  },
];

export const filterFields = (
  organizations: DropdownData[] = [],
  associations: DropdownData[] = [],
): FormField[] => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Pretraga",
        name: "search",
        placeholder: "Pretraži po imenu, lovačkom udruženju ili savezu",
        value: "",
        focus: true,
        required: false,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Rola",
        name: "role_ids",
        placeholder: "Izaberi rolu",
        options: CONSTANTS.roleObjects(true),
        value: "",
        required: false,
      },
    },
    {
      controlType: Dropdown,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez",
        options: associations,
        value: "",
        required: false,
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta/agenciju",
        options: organizations,
        value: "",
        required: false,
      },
    },
    {
      controlType: DatePicker,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Datum od",
        name: "date_from",
        placeholder: "Unesite datum",
        value: "",
        inputtype: "datetime-local",
        required: false,
      },
    },
    {
      controlType: DatePicker,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Datum do",
        name: "date_to",
        placeholder: "Unesite datum",
        value: "",
        required: false,
      },
    },
  ];
};

export const filterQueries = {
  search: "",
  role_ids: "",
  organization_id: "",
  association_id: "",
  date_from: "",
  date_to: "",
};

export const detailsTableColumns = [
  {
    title: "Kolona",
    dataIndex: "column",
    key: "column",
  },
  {
    title: "Prvobitno",
    dataIndex: "original",
    key: "original",
    render: ({ original }: { original: any }) => Utils.getRenderValue(original),
  },
  {
    title: "Izmenjeno",
    dataIndex: "updated",
    key: "updated",
    render: ({ updated }: { updated: any }) => Utils.getRenderValue(updated),
  },
];

export const activityLogFields = (data: ActivityLog): FormField[] => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "ID Moderatora",
        name: "causer_id",
        placeholder: "ID Moderatora",
        value: data?.causer_id ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Ime Moderatora",
        name: "causer.name",
        placeholder: "Ime Moderatora",
        value: data?.causer?.name ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Savez Moderatora",
        name: "causer.association.name",
        placeholder: "Savez",
        value: data?.causer?.association?.name ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija Moderatora",
        name: "causer.organization.name",
        placeholder: "Korisnik lovišta/Agencija",
        value: data?.causer?.organization?.name ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "ID Subjekta",
        name: "subject_id",
        placeholder: "ID Subjekta",
        value: data?.subject_id ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Tip Subjekta",
        name: "subject_type",
        placeholder: "Tip Subjekta",
        value: translateModel(data?.subject_type) ?? "-",
        disabled: true,
      },
    },
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Vreme Izmene",
        name: "created_at",
        placeholder: "Vreme Izmene",
        value: Utils.convertDateTime(data.created_at),
        disabled: true,
      },
    },
  ];
};

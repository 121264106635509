import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import { tableColumns, filterFields, filterQueries } from "./exams-fields";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { ExamsService } from "@/common/API/ExamsService";
import { ExamData } from "@/common/types/Exams";
import { useAuth } from "@/common/context/AuthProvider";
import { useNavigate } from "react-router";
import ROLES from "@/common/enums/roles";
import { Button } from "@/components/ui/button";
import { GoSearch } from "react-icons/go";

const ExamsList = () => {
  const [data, setData] = useState<ExamData | []>([]);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });

  const { loginData } = useAuth();
  const navigate = useNavigate();
  const isExaminer = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Examiner,
  ]);

  const canEdit = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
    ROLES.Examiner,
    ROLES.Inspector,
  ]);

  const getExams = async () => {
    const response = await ExamsService.all(Utils.formatQueryString(query));
    setData({
      data: response?.data?.data,
      pagination: response?.data?.pagination,
    });
  };

  useEffect(() => {
    getExams();
    // eslint-disable-next-line
  }, [query]);

  return (
    <PageLayout
      title="Ispiti"
      showCreateButton={isExaminer}
      buttons={
        <Button
          hide={
            !Utils.hasPermission(loginData?.data?.roles, [
              ROLES.Superadmin,
              ROLES.Moderator,
            ])
          }
          type="button"
          variant="secondary"
          onClick={() => navigate("/exams/documents")}
        >
          Pretraga diploma
          <GoSearch size={17} className="ml-3" />
        </Button>
      }
    >
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields}
        filterQueries={filterQueries}
        hidePrint={true}
        hideDelete={true}
        onEdit={(item) => navigate(`/exams/${item?.id}`)}
        hideEdit={!canEdit}
        hideActions={!canEdit}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
      />
    </PageLayout>
  );
};

export default ExamsList;

export const Attributes = [
  "name",
  "citizenship",
  "unique_identifier",
  "residence",
  "address",
  "gender",
  "hunting_card_type",
  "in_organization",
  "season",
  "month",
  "animal_plan_realizations",
  "animal_plan_realizations.*.animal_id",
  "animal_plan_realizations.*.total",
  "animal_plan_realizations.*.male",
  "animal_plan_realizations.*.female",
  "animal_plan_realizations.*.offspring",
  "animal_plans",
  "animal_plans.*.animal_id",
  "animal_plans.*.native",
  "animal_plans.*.optimal",
  "animal_plans.*.minimal",
  "animal_plans.*.total",
  "animal_plans.*.male",
  "animal_plans.*.female",
  "animal_plans.*.offspring",
  "statement",
  "authorization",
  "payment_slip",
  "user_note",
  "hunting_exam_id",
  "first_name",
  "middle_name",
  "last_name",
  "personal_id",
  "question_group",
  "certificate_number",
  "mark",
  "batch",
  "organization_id",
  "place_of_exam",
  "exam_date",
  "members_of_commission",
  "solution_document",
  "basis_document",
  "basis_added_at",
  "basis_valid_until",
  "surface_area",
  "hunting_ground_ids",
  "hunting_ground_ids.*",
  "user_id",
  "email",
  "password",
  "remember_me",
  "type",
  "association_id",
  "phone",
  "postal_code",
  "bank_account",
  "bank_account2",
  "vat_number",
  "registration_number",
  "admin_note",
  "roles",
  "roles.*",
  "card_type",
  "from_number",
  "to_number",
  "animal_id",
  "stamp_type",
  "token",
  "code",
  "created_at",
  "updated_at",
  "deleted_at",
  "available_cards",
  "status",
  "organizer",
  "available_stamps",

  //Dogs
  "jr_number",
  "chip_number",
  "card_number",
  "breed",
  "variation",
  "dad_name",
  "dad_jr_number",
  "mom_name",
  "mom_jr_number",
  "exam_location",
  "exam_judge",
  "owner_unique_identifier",
];

export const TranslatedAttributes = [
  "ime",
  "državljanstvo",
  "JMBG",
  "mesto",
  "adresa",
  "pol",
  "tip lovne karte",
  "u organizaciji",
  "sezona",
  "mesec",
  "odstreli",
  "životinja",
  "ukupno",
  "mužjak",
  "ženka",
  "podmladak",
  "planovi",
  "životinja",
  "matični fond",
  "optimalni fond",
  "minimalni fond",
  "ukupno",
  "mužjak",
  "ženka",
  "podmlatak",
  "izjava",
  "ovlašćenje",
  "uplatnica",
  "napomena korisnika",
  "lovački ispit",
  "ime",
  "srednje ime",
  "prezime",
  "JMBG",
  "grupa pitanja",
  "broj uverenja",
  "ocena",
  "batch",
  "udruženje",
  "mesto ispita",
  "datum ispita",
  "članovi komisije",
  "dokument rešenja",
  "lovna osnova",
  "osnova dodata u",
  "osnova važi do",
  "površina",
  "lovišta",
  "lovišta",
  "korisnik",
  "email",
  "lozinka",
  "zapamti me",
  "tip",
  "savez",
  "telefon",
  "poštanski broj",
  "žiro račun",
  "žiro račun 2",
  "PIB",
  "matični broj",
  "napomena administratora",
  "uloge",
  "uloge",
  "tip kartice",
  "od broja",
  "do broja",
  "životinja",
  "tip markice",
  "token",
  "kod",
  "kreirano u",
  "ažurirano u",
  "obrisano u",
  "dostupne karte",
  "status",
  "organizator",
  "dostupne markice",

  //Dogs
  "jr broj",
  "broj čipa",
  "broj kartice",
  "pasmina",
  "varijacija",
  "ime oca",
  "jr broj oca",
  "ime majke",
  "jr broj majke",
  "mesto ispita",
  "sudija",
  "JBMG vlasnika",
];

const ucFirst = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const translateAttribute = (
  attribute?: string,
  ucfirst: boolean = true,
): string => {
  if (!attribute) {
    return "-";
  }

  const translated = TranslatedAttributes[Attributes.indexOf(attribute)] ?? "-";

  return ucfirst ? ucFirst(translated) : translated;
};

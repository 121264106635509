import * as Yup from "yup";

export const CreateExamSchema = Yup.object().shape({
  organizer: Yup.string().required("Unesite organizatora"),
  place_of_exam: Yup.string().required("Unesite mesto polaganja"),
  exam_date: Yup.string().required("Unesite datum polaganja"),
  members_of_commission: Yup.array().required("Unesite sve članove komisije"),
});

export const CreateCandidateSchema = Yup.object().shape({
  first_name: Yup.string().required("Unesite ime kandidata"),
  middle_name: Yup.string(),
  last_name: Yup.string().required("Unesite prezime kandidata"),
  personal_id: Yup.mixed(),
  address: Yup.string().required("Unesite adresu kandidata"),
  question_group: Yup.number()
    .typeError("Mora biti broj")
    .required("Unesite grupu pitanja"),
});

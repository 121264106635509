import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./hunting-card-orders-fields";
import { useEffect, useState } from "react";
import { HuntingCardOrdersService } from "@/common/API/HuntingCardOrdersService";
import { ReportsService } from "@/common/API/ReportsService";
import { IHuntingOrders } from "@/common/types/HuntingOrders";
import { useNavigate } from "react-router";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import Utils from "@/common/utils/Utils";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import useDropdownData from "@/common/hooks/useDropdownData";
import StatusTag from "@/components/StatusTag";
import { HuntingCardOrderStatus } from "@/common/enums/hunting";

const HuntingCardOrdersList = () => {
  const [data, setData] = useState<IHuntingOrders[]>([]);
  const [statusCards, setStatusCards] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const { query, setQuery, setFilters, paginationMethods } = useQuery({
    hasPagination: true,
    filterQueries,
  });
  const { associations, organizations } = useDropdownData({
    hasAgency: true,
    query,
  });

  const { loginData, isUser, isInspector, isSuperAdminOrModerator, isAdmin } =
    useAuth();

  const hasUserOrAgencyRole = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.User,
    ROLES.Agency,
  ]);

  const hasAssociation =
    !!loginData?.data?.association ||
    Utils.hasPermission(loginData?.data?.roles, [ROLES.Agency]);

  const navigate = useNavigate();

  const getHuntingCardOrdersReport = async () => {
    const response = await ReportsService.all_hunting_card_orders(
      Utils.formatQueryString(query),
    );

    const results = response?.data?.data?.count_per_status?.reduce(
      (acc: Record<string, any>, result: any) => {
        acc[result?.status] = result;
        return acc;
      },
      {},
    );

    const open = results[HuntingCardOrderStatus.Open];
    const sent = results[HuntingCardOrderStatus.Sent];
    const rejected = results[HuntingCardOrderStatus.Rejected];
    const accepted = results[HuntingCardOrderStatus.Accepted];

    setStatusCards({
      open: {
        cards: open?.hunting_card_count ?? 0,
        orders: open?.hunting_card_order_count ?? 0,
      },
      sent: {
        cards: sent?.hunting_card_count ?? 0,
        orders: sent?.hunting_card_order_count ?? 0,
      },
      accepted: {
        cards: accepted?.hunting_card_count ?? 0,
        orders: accepted?.hunting_card_order_count ?? 0,
      },
      rejected: {
        cards: rejected?.hunting_card_count ?? 0,
        orders: rejected?.hunting_card_order_count ?? 0,
      },
    });
  };

  const getHuntingCardOrders = async () => {
    try {
      setIsFetching(true);
      const response = await HuntingCardOrdersService.all(
        Utils.formatQueryString(query),
      );

      setData(response?.data);

      getHuntingCardOrdersReport();
    } finally {
      setIsFetching(false);
    }
  };

  const onDelete = async (id?: string) => {
    await HuntingCardOrdersService.delete(id);
    getHuntingCardOrders();
  };

  useEffect(() => {
    getHuntingCardOrders();
    // eslint-disable-next-line
  }, [query]);

  return (
    <PageLayout
      title="Zahtevi za lovne karte"
      showCreateButton={Utils.hasPermission(loginData?.data?.roles, [
        ROLES.User,
        ROLES.Agency,
      ])}
      cards={
        statusCards ? (
          <div className="flex items-center space-x-2">
            <StatusTag
              type="in-progress"
              title={`Neodobreno: ${statusCards?.sent?.cards} Lovnih karata (${statusCards?.sent?.orders} zahteva)`}
            />
            {hasUserOrAgencyRole && (
              <StatusTag
                type="open"
                title={`U doradi: ${statusCards?.open?.cards} Lovnih karata (${statusCards?.open?.orders} zahteva)`}
              />
            )}
            <StatusTag
              type="rejected"
              title={`Odbijeno: ${statusCards?.rejected?.cards} Lovnih karata (${statusCards?.rejected?.orders} zahteva)`}
            />
            <StatusTag
              type="accepted"
              title={`Odobreno: ${statusCards?.accepted?.cards} Lovnih karata (${statusCards?.accepted?.orders} zahteva)`}
            />
          </div>
        ) : (
          <></>
        )
      }
    >
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(
          hasAssociation,
          hasUserOrAgencyRole,
          associations,
          organizations,
          isInspector,
          isSuperAdminOrModerator,
          isAdmin,
        )}
        filterQueries={filterQueries}
        onEdit={(item) =>
          isUser
            ? navigate(`/hunting-card-orders/create/${item?.id}`)
            : navigate(`/hunting-card-orders/${item?.id}`)
        }
        onDelete={(id) => onDelete(id)}
        hideEdit={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
            ROLES.User,
            ROLES.Agency,
            ROLES.Inspector,
          ])
        }
        hideDelete={
          !Utils.hasPermission(loginData?.data?.roles, [
            ROLES.Superadmin,
            ROLES.Admin,
            ROLES.Moderator,
          ])
        }
        hidePrint={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        paginationMethods={paginationMethods}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};

export default HuntingCardOrdersList;

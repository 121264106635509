import { useNavigate, useParams } from "react-router";
import FormFactory from "@/components/forms/FormFactory";
import { useEffect, useRef, useState } from "react";
import { organizationsCreateFields } from "./organizations-fields";
import { OrganizationsService } from "@/common/API/OrganizationsService";
import { IOrganization, OrganizationData } from "@/common/types/Organizations";
import { CreateOrganizationSchema } from "@/common/schemas/Organizations";
import PageLayout from "@/components/layout/PageLayout";
import { Button } from "@/components/ui/button";
import Text from "@/components/Text";
import { FiSave } from "react-icons/fi";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import { FormikProps } from "formik";
import useDropdownData from "@/common/hooks/useDropdownData";

const OrganizationsEdit = () => {
  const [data, setData] = useState<IOrganization>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("");

  const { associations } = useDropdownData({ hasAgency: false });
  const { loginData } = useAuth();
  const isAdmin = Utils.hasPermission(loginData?.data?.roles, [
    ROLES.Superadmin,
    ROLES.Admin,
    ROLES.Moderator,
  ]);

  const navigate = useNavigate();
  const { id } = useParams();
  const formRef = useRef<FormikProps<OrganizationData>>(null);

  const getOrganizationData = async () => {
    const response = await OrganizationsService.one(id);

    const data = response?.data?.data;

    formRef?.current?.setValues({
      ...data,
      association_id: data?.association?.id
        ? String(data?.association?.id)
        : null,
    });

    setFormType(data?.association ? "1" : "2");
    setData(data);
  };

  useEffect(() => {
    getOrganizationData();
  }, [id]);

  const onSubmit = async (values: OrganizationData) => {
    try {
      setIsLoading(true);

      await OrganizationsService.update(id, {
        ...values,
        type: formType,
        association_id: formType === "1" ? values.association_id : null,
      });

      // navigate(`/organizations`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      title="Pregled korisnika lovišta/agencije"
      backUrl="./../"
      buttons={
        <Button
          type="button"
          onClick={() => formRef?.current?.handleSubmit()}
          isLoading={isLoading}
          hide={!isAdmin}
        >
          Sačuvaj
          <FiSave className="ml-3" />
        </Button>
      }
    >
      <div className="space-y-4">
        <FormFactory
          formFields={organizationsCreateFields(
            formType,
            setFormType,
            associations,
          )}
          onSubmit={onSubmit}
          validationSchema={CreateOrganizationSchema}
          formRef={formRef}
        />
        <Text variant="label">
          {data?.editor?.name && `Poslednji izmenio: ${data?.editor?.name}`}
        </Text>
      </div>
    </PageLayout>
  );
};

export default OrganizationsEdit;

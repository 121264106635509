import { AnimalInputType } from "@/common/enums/animals";
import ROLES from "@/common/enums/roles";
import { DropdownData, FilterableDropdownData } from "@/common/types/Form";
import { IUser } from "@/common/types/User";
import QueryUtils from "@/common/utils/QueryUtils";
import Utils from "@/common/utils/Utils";
import Text from "@/components/Text";
import AnimalInput from "@/components/forms/AnimalInput";
import MultiSelectDropdown from "@/components/forms/MultipleSelectDropdown";

export const tableColumns = (
  onAnimalInputChange: (
    animal_id: number,
    name: string,
    val: number,
    type: number,
  ) => void,
) => {
  return [
    {
      title: "Vrsta divljači",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Matični fond",
      dataIndex: "native",
      key: "native",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="native"
          placeholder="Matični broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Optimalni fond",
      dataIndex: "optimal",
      key: "optimal",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="optimal"
          placeholder="Optimalni broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Minimalni fond",
      dataIndex: "minimal",
      key: "minimal",
      render: ({ input_type, id }: { input_type: number; id: number }) => (
        <AnimalInput
          name="minimal"
          placeholder="Minimalni broj"
          onChange={(name: string, val: number) =>
            onAnimalInputChange(id, name, val, input_type)
          }
        />
      ),
    },
    {
      title: "Mužjak - planirani odstrel",
      dataIndex: "male",
      key: "male",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="male"
            placeholder="Mužjak - odstrel"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Ženka - planirani odstrel",
      dataIndex: "female",
      key: "female",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="female"
            placeholder="Ženka - odstrel"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Podmladak - planirani odstrel",
      dataIndex: "offspring",
      key: "offspring",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <AnimalInput
            name="offspring"
            placeholder="Podmladak - odstrel"
            disabled={input_type === AnimalInputType.Total}
            onChange={(name: string, val: number) =>
              onAnimalInputChange(id, name, val, input_type)
            }
          />
        );
      },
    },
    {
      title: "Ukupno - planirani odstrel",
      dataIndex: "total",
      key: "total",
      render: ({ input_type, id }: { input_type: number; id: number }) => {
        return (
          <div>
            <AnimalInput
              name="total"
              placeholder="Ukupno - odstrel"
              disabled={input_type === AnimalInputType.Partial}
              onChange={(name: string, val: number) =>
                onAnimalInputChange(id, name, val, input_type)
              }
            />
          </div>
        );
      },
    },
  ];
};

export const huntingPlanTableColumns = [
  {
    title: "Vrsta divljači",
    dataIndex: ["animal", "name"],
    key: ["animal", "name"],
  },
  {
    title: "Matični fond",
    dataIndex: "native",
    key: "native",
  },
  {
    title: "Optimalni fond",
    dataIndex: "optimal",
    key: "optimal",
  },
  {
    title: "Minimalni fond",
    dataIndex: "minimal",
    key: "minimal",
  },
  {
    title: "Mužjak",
    dataIndex: "male",
    key: "male",
    render: ({ male }: { male: number }) => (
      <Text variant="table">{male ? male : "-"}</Text>
    ),
  },
  {
    title: "Ženka",
    dataIndex: "female",
    key: "female",
    render: ({ female }: { female: number }) => (
      <Text variant="table">{female ? female : "-"}</Text>
    ),
  },
  {
    title: "Podmladak",
    dataIndex: "offspring",
    key: "offspring",
    render: ({ offspring }: { offspring: number }) => (
      <Text variant="table">{offspring ? offspring : "-"}</Text>
    ),
  },
  {
    title: "Ukupno",
    dataIndex: "total",
    key: "total",
    render: ({ total }: { total: number }) => (
      <Text variant="table">{total ? total : "-"}</Text>
    ),
  },
];

export const filterFields = (
  organizations?: DropdownData[],
  huntingGrounds?: FilterableDropdownData[],
  seasons?: DropdownData[],
  revisions?: DropdownData[],
  query?: Record<string, any>,
  user?: IUser,
) => {
  return [
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik Lovišta",
        name: "organization_ids",
        placeholder: "Izaberite korisnika lovišta",
        countablePlural: "korisnika lovišta",
        searchablePlural: "korisnike lovišta",
        value: "",
        options: organizations,
        hide: !Utils.hasPermission(user?.roles, [
          ROLES.Superadmin,
          ROLES.Moderator,
          ROLES.Inspector,
        ]),
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovište",
        name: "hunting_ground_ids",
        placeholder: "Izaberite lovište",
        countablePlural: "lovišta",
        searchablePlural: "lovišta",
        value: "",
        options: QueryUtils.filterDropdownData(huntingGrounds, query),
        hide: !Utils.hasPermission(user?.roles, [
          ROLES.Superadmin,
          ROLES.Moderator,
          ROLES.Admin,
          ROLES.User,
          ROLES.Agency,
          ROLES.Inspector,
        ]),
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Sezone",
        name: "seasons",
        placeholder: "Izaberite sezonu",
        countablePlural: "sezona",
        searchablePlural: "sezone",
        value: "",
        options: seasons,
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Revizija",
        name: "revision_number",
        placeholder: "Izaberite reviziju",
        value: "",
        options: revisions,
        hide: revisions?.length! <= 1,
      },
    },
  ];
};

export const filterQueries = {
  organization_ids: "",
  hunting_ground_ids: "",
  seasons: "",
  revision_number: "",
};

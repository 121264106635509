import { FormField } from "@/common/types/Form";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import { Button } from "../ui/button";
import Utils from "@/common/utils/Utils";
import { RefObject } from "react";

type InitialValues = {
  [key: string]: string;
};

const FormFactory = ({
  formFields,
  formRef,
  onSubmit,
  validationSchema,
  className = "grid grid-cols-12 gap-5",
  autoSubmit = false,
}: {
  formFields: any;
  formRef?: RefObject<FormikProps<any>>;
  onSubmit?: (values: any) => void;
  validationSchema?: Yup.ObjectSchema<Yup.AnyObject>;
  className?: string;
  autoSubmit?: boolean;
}) => {
  const debouncedHandleSubmit = Utils?.debounce(() => {
    formRef?.current?.handleSubmit?.();
  }, 800);

  const generateFormFields = (fields: FormField[]) => {
    return fields.map((field, index) => {
      if (field.controlType) {
        return (
          !field?.options?.hide && (
            <Field
              key={index}
              component={field.controlType}
              className={field.className}
              // change to only this and test when you have time
              // {...field?.options}
              name={field?.options?.name}
              placeholder={field?.options?.placeholder}
              label={field?.options?.label}
              title={field?.options?.title}
              countablePlural={field?.options?.countablePlural}
              searchablePlural={field?.options?.searchablePlural}
              url={field?.options?.url}
              disabled={field?.options?.disabled}
              accept={field?.options?.accept}
              options={field?.options?.options}
              required={field?.options?.required}
              callback={field?.options?.callback}
              focus={field?.options?.focus}
              inputtype={field?.options?.inputtype}
              unit={field?.options?.unit}
              showreset={field?.options?.showReset}
              submit={autoSubmit ? formRef?.current?.handleSubmit : () => {}}
            />
          )
        );
      } else if (field?.render) {
        return field?.render(index);
      } else {
        return (
          !field?.options?.hide && (
            <Button
              type={field?.options?.type}
              className={field?.className}
              key={index}
              isLoading={field?.options?.isLoading}
              onClick={field?.options?.onClick}
              variant="secondary"
            >
              {field?.options?.title}
            </Button>
          )
        );
      }
    });
  };

  const generateFormInitialValues = (fields: FormField[]) => {
    const initialValues: InitialValues = {};

    fields.forEach((field: FormField) => {
      if (field?.options?.name) {
        initialValues[field?.options?.name] = field?.options?.value;
      }
    });

    return initialValues;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={generateFormInitialValues(formFields)}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit ? (vals) => onSubmit?.(vals) : () => {}}
    >
      <Form
        // onChange={autoSubmit ? debouncedHandleSubmit : () => {}}
        // onBlur={
        //   autoSubmit ? () => formRef?.current?.handleSubmit?.() : () => {}
        // }
        className={className}
      >
        {generateFormFields(formFields)}
      </Form>
    </Formik>
  );
};

export default FormFactory;

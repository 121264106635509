import { Field, Form, Formik, FormikProps } from "formik";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../common/context/AuthProvider";
import { AuthService } from "../../common/API/AuthService";
import { Link } from "react-router-dom";
import Input from "../../components/forms/Input";
import { Button } from "@/components/ui/button";
import PasswordInput from "@/components/forms/PasswordInput";
import { LoginSchema } from "@/common/schemas/Auth";
import Checkbox from "@/components/forms/Checkbox";
import { LoginValues } from "@/common/types/Auth";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { login, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<LoginValues>>(null);

  const handleSubmit = async (values: {
    email: string;
    password: string;
    remember_me: boolean;
  }) => {
    try {
      setIsLoading(true);
      const response = await AuthService.login(values);

      if (response?.data) {
        login(response?.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) navigate("/hunting-cards");
  }, [isLoggedIn]);

  return (
    <main className="flex flex-col items-center bg-white p-8">
      <Formik
        innerRef={formRef}
        initialValues={{
          email: "",
          password: "",
          remember_me: false,
        }}
        validationSchema={LoginSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form>
          <div className="mb-2 space-y-5">
            <Field
              name="email"
              type="email"
              placeholder="Email"
              label="Email"
              component={Input}
              disabled={isLoading}
              focus={true}
              autoComplete="email"
              className="min-w-72"
            />
            <Field
              name="password"
              placeholder="Lozinka"
              label="Lozinka"
              disabled={isLoading}
              component={PasswordInput}
              autoComplete="current-password"
            />
            <Field
              name="remember_me"
              placeholder="Zapamti me"
              label="Zapamti me"
              disabled={isLoading}
              component={Checkbox}
            />
          </div>
          <Button type="submit" className="mt-6 w-full" isLoading={isLoading}>
            Login
          </Button>
          <Link
            to={"/forgot-password"}
            className="mt-2 block text-xs text-primary"
          >
            Zaboravili ste lozinku?
          </Link>
        </Form>
      </Formik>
    </main>
  );
};

export default Login;

import { DropdownData, FilterableDropdownData } from "@/common/types/Form";
import Text from "@/components/Text";
import MultiSelectDropdown from "@/components/forms/MultipleSelectDropdown";

export const tableColumns = [
  {
    title: "Vrsta divljači",
    dataIndex: ["animal", "name"],
    key: ["animal", "name"],
  },
  {
    title: "Mužjak",
    dataIndex: "total_male",
    key: "total_male",
    render: (data: any, index: number) => {
      return (
        <Text variant="table">
          {data?.total_male === null ? "-" : data?.total_male}
        </Text>
      );
    },
  },
  {
    title: "Ženka",
    dataIndex: "total_female",
    key: "total_female",
    render: (data: any, index: number) => {
      return (
        <Text variant="table">
          {data?.total_female === null ? "-" : data?.total_female}
        </Text>
      );
    },
  },
  {
    title: "Podmladak",
    dataIndex: "total_ofspring",
    key: "total_ofspring",
    render: (data: any, index: number) => {
      return (
        <Text variant="table">
          {data?.total_offspring === null ? "-" : data?.total_offspring}
        </Text>
      );
    },
  },
  {
    title: "Ukupno",
    dataIndex: "total",
    key: "total",
    render: (data: any, index: number) => {
      return (
        <Text variant="table">{data?.total === null ? "-" : data?.total}</Text>
      );
    },
  },
];

export const filterFields = (
  associations?: DropdownData[],
  organizations?: FilterableDropdownData[],
  animals?: DropdownData[],
  huntingGrounds?: FilterableDropdownData[],
  seasons?: DropdownData[],
  query?: Record<string, any | Array<number>>,
) => {
  if (Boolean(query?.organization_id?.length)) {
    huntingGrounds = huntingGrounds?.filter((huntingGround) =>
      query?.organization_id?.includes(huntingGround?.organization_id),
    );
  }

  if (Boolean(query?.association_id?.length)) {
    organizations = organizations?.filter((organization) =>
      query?.association_id?.includes(organization?.association_id),
    );

    huntingGrounds = huntingGrounds?.filter((huntingGround) =>
      query?.association_id?.includes(huntingGround?.association_id),
    );
  }

  return [
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Savez",
        name: "association_id",
        placeholder: "Izaberi savez",
        countablePlural: "saveza",
        searchablePlural: "saveze",
        options: associations ?? [],
        value: "",
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Korisnik",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta",
        countablePlural: "korisnika",
        searchablePlural: "korisnike",
        options: organizations ?? [],
        value: "",
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Lovište",
        name: "hunting_ground_id",
        placeholder: "Izaberi lovište",
        countablePlural: "lovišta",
        searchablePlural: "lovišta",
        options: huntingGrounds ?? [],
        value: "",
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Sezona",
        name: "season",
        placeholder: "Izaberi sezonu",
        countablePlural: "sezona",
        searchablePlural: "sezone",
        options: seasons ?? [],
        value: "",
      },
    },
    {
      controlType: MultiSelectDropdown,
      className: "md:col-span-2 col-span-12",
      options: {
        label: "Divljač",
        name: "animal_id",
        placeholder: "Izaberi divljač",
        countablePlural: "divljači",
        searchablePlural: "divljač",
        options: animals ?? [],
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  organization_id: "",
  hunting_ground_id: "",
  association_id: "",
  season: "",
  animal_id: "",
};

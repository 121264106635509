import PageLayout from "@/components/layout/PageLayout";
import Table from "@/components/table/Table";
import Utils from "@/common/utils/Utils";
import { useQuery } from "@/common/hooks/useQuery";
import { useEffect, useState } from "react";
import {
  filterFields,
  filterQueries,
  tableColumns,
} from "./shooting-statistics-fields";
import { ReportsService } from "@/common/API/ReportsService";
import { ShootingReportResponse } from "@/common/types/Reports";
import { DropdownData, FilterableDropdownData } from "@/common/types/Form";
import { AnimalsService } from "@/common/API/AnimalsService";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { AssociationsService } from "@/common/API/AssociationsService";
import { OrganizationsService } from "@/common/API/OrganizationsService";

const ShootingStatisticsList = () => {
  const [data, setData] = useState<ShootingReportResponse | []>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [associations, setAssociations] = useState<DropdownData[]>([]);
  const [organizations, setOrganizations] = useState<FilterableDropdownData[]>(
    [],
  );
  const [huntingGrounds, setHuntingGrounds] = useState<
    FilterableDropdownData[]
  >([]);
  const [seasons, setSeasons] = useState<DropdownData[]>([]);
  const [animals, setAnimals] = useState<DropdownData[]>([]);
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });

  const getAssociations = async () => {
    const response = await AssociationsService.all_dropdown();

    setAssociations((prev) => response?.data?.data ?? prev);
  };

  const getOrganizations = async () => {
    const response = await OrganizationsService.all_dropdown();

    setOrganizations((prev) => response?.data?.data ?? prev);
  };

  const getAnimals = async () => {
    const response = await AnimalsService.all();

    setAnimals((prev) => response?.data?.data ?? prev);
  };

  const getHuntingGruonds = async () => {
    const response = await HuntingGroundsService.all_dropdown();
    setHuntingGrounds((prev) => response?.data?.data ?? prev);
  };

  const getSeasons = (data: string[]) => {
    const formattedSeasons: DropdownData[] = data?.map(
      (season: string): DropdownData => ({
        name: season,
        id: season,
      }),
    );

    setSeasons(formattedSeasons);
  };

  const getShootingReports = async () => {
    try {
      setIsFetching(true);
      const response = await ReportsService.shooting_statistics(
        Utils.formatMultipleOptionsQueryString(query),
      );

      setData((prev) => response?.data?.data?.data ?? prev);

      getSeasons(response?.data?.data?.available_seasons);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getAssociations();
    getOrganizations();
    getAnimals();
    getHuntingGruonds();
  }, []);

  useEffect(() => {
    const fetchData = setTimeout(() => getShootingReports(), 400);

    return () => clearTimeout(fetchData);
  }, [query]);

  return (
    <PageLayout title="Statistika odstrela">
      <Table
        data={data}
        columns={tableColumns}
        filterFields={filterFields(
          associations,
          organizations,
          animals,
          huntingGrounds,
          seasons,
          query,
        )}
        filterQueries={filterQueries}
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};
export default ShootingStatisticsList;

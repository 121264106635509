import { DropdownData, FilterableDropdownData } from "../types/Form";

const QueryUtils = {
  getQueryFilterString: (query: Record<string, any | any[]>): string => {
    let filter = "?";

    for (const key in query) {
      const value: string[] | string = query[key];

      if (Boolean(value?.length)) {
        if (typeof value === "object") {
          filter = filter.concat(`${key}[]=${value.join(`&${key}[]=`)}&`);
        } else if (typeof value === "string") {
          filter = filter.concat(`${key}=${value}&`);
        }
      }
    }

    return filter.length > 1 ? filter.substring(0, filter.length - 1) : "?";
  },
  filterDropdownData: (
    options: FilterableDropdownData[] = [],
    query?: Record<string, any | any[]>,
  ): FilterableDropdownData[] => {
    const associationIds = query?.association_ids ?? query?.association_id;
    const organizationIds = query?.organization_ids ?? query?.organization_id;

    if (Boolean(associationIds?.length)) {
      options = options?.filter((option) =>
        associationIds?.includes(option?.association_id),
      );
    }

    if (Boolean(organizationIds?.length)) {
      options = options?.filter((option) =>
        organizationIds?.includes(option?.organization_id),
      );
    }

    return options;
  },
  formatAvailableSeasons: (seasons: string[]): DropdownData[] => {
    return seasons?.map(
      (season: string): DropdownData => ({
        name: season,
        id: season,
      }),
    );
  },
};

export default QueryUtils;

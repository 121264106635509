import config from "../config/config";
import { axios } from "../config/axios";

export const ProfileService = {
  async get() {
    const response = await axios.get(
      `${config.baseUrl}/user/profile-information`,
    );

    return response;
  },
};

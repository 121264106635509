import * as Yup from "yup";

export const CreateOrganizationSchema = Yup.object().shape({
  name: Yup.string().required("Unesite ime"),
  address: Yup.string().required("Unesite adresu"),
  residence: Yup.string().required("Unesite mesto"),
  postal_code: Yup.string().required("Unesite poštanski broj"),
  email: Yup.string().email("Email nije validan").required("Unesite email"),
  phone: Yup.string()
    .matches(/^[0-9/+-]+$/, "Unesite validan broj telefona")
    .required("Unesite broj telefona"),
  bank_account: Yup.string()
    .matches(/^[0-9/+-]+$/, "Unesite validan žiro račun")
    .test(
      "len",
      "Žiro račun 1 ne sme imati više od 20 karaktera",
      (val) => val?.toString().length! <= 20,
    )
    .required("Unesite žiro račun"),
  bank_account2: Yup.string()
    .matches(/^[0-9/+-]+$/, "Unesite validan žiro račun")
    .test(
      "len",
      "Žiro račun 2 ne sme imati više od 20 karaktera",
      (val) => val?.toString().length! <= 20,
    )
    .required("Unesite žiro račun"),
  vat_number: Yup.number()
    .typeError("PIB mora biti broj")
    .test(
      "len",
      "PIB ne sme imati više od 10 karaktera",
      (val) => val?.toString().length! <= 10,
    )
    .required("Unesite PIB"),
  registration_number: Yup.number()
    .typeError("Matični broj mora biti broj")
    .test(
      "len",
      "Matični broj ne sme imati više od 8 karaktera",
      (val) => val?.toString().length! <= 8,
    )
    .required("Unesite matični broj"),
  association_id: Yup.string().nullable().optional(),
});

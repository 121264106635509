import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import Table from "@/components/table/Table";
import {
  filterFields,
  filterQueries,
  huntingPlanTableColumns,
  tableColumns,
} from "./hunting-plan-fields";
import { HuntingGroundsService } from "@/common/API/HuntingGroundsService";
import { AnimalsService } from "@/common/API/AnimalsService";
import {
  AnimalData,
  AnimalHuntingPlanResponse,
  AnimalStamp,
} from "@/common/types/Animals";
import { DropdownData } from "@/common/types/Form";
import Utils from "@/common/utils/Utils";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import HuntingUtils from "@/common/utils/HuntingUtils";
import { GrDocumentDownload } from "react-icons/gr";
import PageLayout from "@/components/layout/PageLayout";
import { TiPlusOutline } from "react-icons/ti";
import { BsSend } from "react-icons/bs";
import { useQuery } from "@/common/hooks/useQuery";
import { useNavigate } from "react-router";
import { OrganizationsService } from "@/common/API/OrganizationsService";
import QueryUtils from "@/common/utils/QueryUtils";

const HuntingPlanList = () => {
  const [animals, setAnimals] = useState<AnimalStamp[] | null>(null);
  const [huntingPlan, setHuntingPlan] =
    useState<AnimalHuntingPlanResponse | null>(null);
  const [organizations, setOrganizations] = useState<DropdownData[]>([]);
  const [huntingGrounds, setHuntingGrouds] = useState<DropdownData[]>([]);
  const [animalInputData, setAnimalInputData] = useState<AnimalData[]>([]);
  const [seasons, setSeasons] = useState<DropdownData[]>([]);
  const [revisions, setRevisions] = useState<DropdownData[] | undefined>([]);
  const [huntingGroundId, setHuntingGroundId] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { loginData } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const navigate = useNavigate();

  const isSingleSeason =
    query?.seasons?.length === 1 && typeof query?.seasons === "object";

  const isUser = Utils.hasPermission(loginData?.data?.roles, [ROLES.User]);
  const hasAssociation = !!loginData?.data?.association?.id;
  const hasOrganization = !!loginData?.data?.organization?.id;

  const isSingleHuntingGround =
    query?.hunting_ground_ids?.length === 1 &&
    typeof query?.hunting_ground_ids === "object";

  const getOrganizations = async () => {
    if (
      !Utils.hasPermission(loginData?.data?.roles, [
        ROLES.Superadmin,
        ROLES.Moderator,
        ROLES.Inspector,
      ])
    ) {
      return;
    }

    const response = await OrganizationsService.all_dropdown();

    setOrganizations(response?.data?.data ?? []);
  };

  const getHuntingGrounds = async () => {
    if (
      !Utils.hasPermission(loginData?.data?.roles, [
        ROLES.Superadmin,
        ROLES.Moderator,
        ROLES.Admin,
        ROLES.User,
        ROLES.Inspector,
      ])
    ) {
      return;
    }

    const associationId = hasAssociation
      ? String(loginData?.data?.association?.id)
      : undefined;

    const organizationId = hasOrganization
      ? String(loginData?.data?.organization?.id)
      : undefined;

    const response = await HuntingGroundsService.all_dropdown(
      organizationId,
      associationId,
    );

    const huntingGrounds = response?.data?.data;
    setHuntingGrouds(huntingGrounds);

    if (isSingleHuntingGround || huntingGrounds?.length === 1) {
      setHuntingGroundId(huntingGrounds[0]?.id);
    }
  };

  const getHuntingPlan = async () => {
    const response = await HuntingGroundsService.getHuntingPlan(query);

    setHuntingPlan(response?.data);
    setAnimals(null);
    setSeasons(
      QueryUtils.formatAvailableSeasons(
        response?.data?.data?.available_seasons,
      ),
    );
  };

  const getAnimals = async () => {
    const response = await AnimalsService.all();
    setAnimals(response?.data);
  };

  const getRevisions = async () => {
    if (!huntingGroundId || !isSingleSeason) {
      setRevisions([]);
      return;
    }

    const response = await HuntingGroundsService.getRevisions(
      huntingGroundId,
      query?.seasons[0],
    );

    const revisionData = HuntingUtils.formatRevisionData(response?.data?.data);

    setRevisions(revisionData);
  };

  const onSubmit = async (data: AnimalData[]) => {
    await HuntingGroundsService.createHuntingPlan(huntingGroundId, data);

    getHuntingPlan();
    setAnimals(null);
    setAnimalInputData([]);
  };

  const onDownloadDocument = async () => {
    setIsLoading(true);

    const response = await HuntingGroundsService.getCompensationForm(
      huntingGroundId!,
      query?.revision_number,
      query?.seasons[0],
    );

    Utils.openDocument(response?.data?.data);
    setIsLoading(false);
  };

  const onAnimalInputChange = (
    animal_id: number,
    name: string,
    val: number,
    type: number,
  ) => {
    setAnimalInputData((prevData: AnimalData[]) =>
      HuntingUtils.handleAnimalPlanInput(animal_id, name, val, type, prevData),
    );
  };

  useEffect(() => {
    getOrganizations();
    getHuntingGrounds();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getHuntingPlan();

    if (isSingleHuntingGround) {
      setHuntingGroundId(query?.hunting_ground_ids[0]);

      getRevisions();
    } else {
      setRevisions([]);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (huntingPlan?.data?.animal_hunting_plans?.length === 0) {
      getAnimals();
    }
  }, [huntingPlan]);

  return (
    <PageLayout
      title="Godišnji plan"
      buttons={
        <div className="flex flex-row items-center space-x-2">
          {animals && (
            <Button
              type="button"
              onClick={() => onSubmit(animalInputData)}
              hide={!isUser || !isSingleHuntingGround || !isSingleSeason}
            >
              Pošalji
              <BsSend className="ml-3" />
            </Button>
          )}

          {huntingPlan?.data?.animal_hunting_plans && !animals && (
            <Button
              type="button"
              onClick={() =>
                navigate(`/hunting-plan/revision/${huntingGroundId}`)
              }
              hide={!isUser || !isSingleHuntingGround}
            >
              Kreiraj reviziju
              <TiPlusOutline className="ml-3" />
            </Button>
          )}

          {huntingPlan?.data?.animal_hunting_plans && !animals && (
            <Button
              type="button"
              variant="secondary"
              onClick={onDownloadDocument}
              isLoading={isLoading}
              hide={
                !Utils.hasPermission(loginData?.data?.roles, [
                  ROLES.Superadmin,
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Moderator,
                  ROLES.Inspector,
                ]) ||
                !isSingleHuntingGround ||
                !isSingleSeason
              }
            >
              Dokument naknade
              <GrDocumentDownload className="ml-3 text-text" />
            </Button>
          )}
        </div>
      }
    >
      {/* Render table with hunting plan values if it exists, otherwise render animal inputs (only for user) */}
      {huntingPlan?.data?.animal_hunting_plans?.length !== 0 ? (
        <Table
          data={{ data: huntingPlan?.data?.animal_hunting_plans }}
          columns={huntingPlanTableColumns}
          filterFields={filterFields(
            organizations,
            huntingGrounds,
            seasons,
            revisions,
            query,
            loginData?.data,
          )}
          filterQueries={filterQueries}
          hideActions={true}
          hidePagination={true}
          hideReset={false}
          query={query}
          setQuery={setQuery}
          setFilters={setFilters}
        />
      ) : (
        <Table
          data={isUser ? animals : { data: [] }}
          columns={tableColumns(onAnimalInputChange)}
          filterFields={filterFields(
            organizations,
            huntingGrounds,
            seasons,
            revisions,
            query,
            loginData?.data,
          )}
          filterQueries={filterQueries}
          hideActions={true}
          hidePagination={true}
          query={query}
          setQuery={setQuery}
          setFilters={setFilters}
        />
      )}
    </PageLayout>
  );
};

export default HuntingPlanList;

import { useQuery } from "@/common/hooks/useQuery";
import PageLayout from "@/components/layout/PageLayout";
import TableFilterForm from "@/components/table/TableFilterForm";
import { useEffect, useRef, useState } from "react";
import {
  filterFields,
  filterQueries,
  warehouseHuntingCardsAddFields,
} from "./warehouse-hunting-cards-fields";
import { WarehouseHuntingCardsService } from "@/common/API/WarehouseHuntingCardsService";
import Utils from "@/common/utils/Utils";
import {
  WarehouseHuntingCard,
  WarehouseHuntingCardCreateData,
  WarehouseHuntingCardData,
} from "@/common/types/Warehouse";
import { Button } from "@/components/ui/button";
import { TiPlusOutline } from "react-icons/ti";
import Modal from "@/components/Modal";
import FormFactory from "@/components/forms/FormFactory";
import { CreateHuntingCardsSchema } from "@/common/schemas/Warehouse";
import HuntingCardCollapsible from "@/components/HuntingCardCollapsible";
import { RxValueNone } from "react-icons/rx";
import { useAuth } from "@/common/context/AuthProvider";
import ROLES from "@/common/enums/roles";
import useDropdownData from "@/common/hooks/useDropdownData";
import { FormikProps } from "formik";

const WarehouseHuntingCardsList = () => {
  const [huntingCards, setHuntingCards] = useState<WarehouseHuntingCardData[]>(
    [],
  );
  const [lastAvailableCardNumber, setAvailableCardNumber] = useState<number>(0);
  const [editHuntingCard, setEditHuntingCard] =
    useState<WarehouseHuntingCard>();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);

  const { loginData, isAdmin } = useAuth();
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });
  const { associations, organizations, organizationsWithWarehouse } =
    useDropdownData({ hasAgency: true, query });

  const filtersFormRef = useRef<FormikProps<any>>(null);
  const huntingCardsFormRef =
    useRef<FormikProps<WarehouseHuntingCardCreateData>>(null);
  const huntingCardsEditFormRef =
    useRef<FormikProps<WarehouseHuntingCardCreateData>>(null);

  const getHuntingCards = async () => {
    const response = await WarehouseHuntingCardsService.all(
      Utils.formatQueryString(query),
    );

    setHuntingCards(response?.data?.data);
  };

  const getLastAvailableCardNumber = async () => {
    const response = await WarehouseHuntingCardsService.getLastNumber();
    setAvailableCardNumber((response?.data?.data ?? 0) + 1);
  };

  useEffect(() => {
    getLastAvailableCardNumber();

    isAdmin &&
      setQuery((prevState: Record<string, string>) => ({
        ...prevState,
        association_id: String(loginData?.data?.association?.id),
      }));
  }, []);

  useEffect(() => {
    for (let queryKey in filterQueries) {
      filtersFormRef?.current?.setFieldValue(queryKey, query[queryKey]);
    }
  }, [filtersFormRef?.current]);

  useEffect(() => {
    if (
      query?.association_id &&
      (Number(query.association_id) !== -1 || query?.organization_id)
    ) {
      getHuntingCards();
    }
  }, [query]);

  const onAddHuntingCards = async (data: WarehouseHuntingCardCreateData) => {
    await WarehouseHuntingCardsService.create({
      ...data,
      for_organization: Number(query?.association_id) === -1,
    });

    getHuntingCards();
    setOpenCreateModal(false);
  };

  const onDeleteHuntingCard = async (id: string) => {
    await WarehouseHuntingCardsService.delete(id);

    getHuntingCards();
  };

  const onEditHuntingCard = async (data: WarehouseHuntingCardCreateData) => {
    await WarehouseHuntingCardsService.update(editHuntingCard?.id, {
      ...data,
      for_organization: Number(query?.association_id) === -1,
    });

    getHuntingCards();
    setOpenEditModal(false);
  };

  return (
    <PageLayout
      title="Magacin lovnih karata"
      buttons={
        query?.association_id ? (
          <Modal
            open={openCreateModal}
            setOpen={setOpenCreateModal}
            triggerButton={
              <Button
                hide={
                  !Utils.hasPermission(loginData?.data?.roles, [
                    ROLES.Superadmin,
                    ROLES.Moderator,
                  ])
                }
              >
                Dodaj
                <TiPlusOutline className="ml-3" />
              </Button>
            }
            headerTitle="Dodaj lovne karte"
            buttonTitle="Dodaj"
            onSubmit={() => huntingCardsFormRef?.current?.handleSubmit()}
            content={
              <FormFactory
                formFields={warehouseHuntingCardsAddFields(
                  associations,
                  organizations,
                  lastAvailableCardNumber,
                  query,
                )}
                onSubmit={onAddHuntingCards}
                formRef={huntingCardsFormRef}
                validationSchema={CreateHuntingCardsSchema}
              />
            }
          />
        ) : (
          <></>
        )
      }
    >
      <div className="space-y-4">
        {!isAdmin && (
          <TableFilterForm
            showFilters={true}
            filterFields={filterFields(
              associations,
              organizationsWithWarehouse,
              query,
            )}
            onSubmit={setFilters}
            formRef={filtersFormRef}
          />
        )}
        {huntingCards?.length !== 0 ? (
          <HuntingCardCollapsible
            data={huntingCards}
            onDelete={onDeleteHuntingCard}
            onEdit={(item) => {
              setEditHuntingCard(item);
              setOpenEditModal(!openEditModal);
            }}
            roles={loginData?.data?.roles}
          />
        ) : (
          <div className="flex items-center justify-center  rounded-lg border border-gray-200 p-4">
            <RxValueNone size={20} className="text-gray-600" />
            <p className="ml-1 text-xs text-gray-500">
              Nema dodatih lovnih karata
            </p>
          </div>
        )}

        <Modal
          open={openEditModal}
          setOpen={setOpenEditModal}
          headerTitle="Izmeni lovne karte"
          buttonTitle="Izmeni"
          onSubmit={() => huntingCardsEditFormRef?.current?.handleSubmit()}
          content={
            <FormFactory
              formFields={warehouseHuntingCardsAddFields(
                associations,
                organizations,
                lastAvailableCardNumber,
                query,
                editHuntingCard,
              )}
              onSubmit={onEditHuntingCard}
              formRef={huntingCardsEditFormRef}
              validationSchema={CreateHuntingCardsSchema}
            />
          }
        />
      </div>
    </PageLayout>
  );
};

export default WarehouseHuntingCardsList;

import { DropdownData } from "@/common/types/Form";
import { IDocument } from "@/common/types/HuntingOrders";
import Utils from "@/common/utils/Utils";
import DocumentLink from "@/components/DocumentLink";
import DocumentUpload from "@/components/DocumentUpload";
import Text from "@/components/Text";
import Dropdown from "@/components/forms/Dropdown";
import DropdownSearch from "@/components/forms/DropdownSearch";
import Input from "@/components/forms/Input";

export const tableColumns = (
  onUpload?: (id?: string, file?: File) => void,
  onDelete?: (id?: string) => void,
  canEditDocument?: boolean,
  isInspector?: boolean,
) => {
  return [
    {
      title: "Naziv lovišta",
      dataIndex: "name",
      key: "name",
      sortOptions: {
        sortDirection: "",
        sortParam: "name",
      },
    },
    {
      title: "Naziv korisnika lovišta",
      dataIndex: ["organization", "name"],
      key: ["organization", "name"],
    },
    {
      title: "Površina (km²)",
      dataIndex: "surface_area",
      key: "surface_area",
      sortOptions: {
        sortDirection: "",
        sortParam: "surface_area",
      },
      render: ({ surface_area }: { surface_area: number }) => (
        <Text variant="table">{surface_area ?? "-"}</Text>
      ),
    },
    {
      title: "Datum kreiranja",
      dataIndex: "created_at",
      key: "created_at",
      render: ({ created_at }: { created_at: string }) => (
        <Text variant="table">{Utils.convertTime(created_at)}</Text>
      ),
      sortOptions: {
        sortDirection: "",
        sortParam: "created_at",
      },
    },
    {
      title: "Datum trajanja",
      dataIndex: "basis_valid_until",
      key: "basis_valid_until",
      render: ({ basis_valid_until }: { basis_valid_until: string }) => (
        <Text variant="table">
          {basis_valid_until ? Utils.convertTime(basis_valid_until) : "-"}
        </Text>
      ),
      sortOptions: {
        sortDirection: "",
        sortParam: "basis_valid_until",
      },
    },
    {
      title: "Lovna osnova",
      dataIndex: ["basis_document", "name"],
      key: ["basis_document", "name"],
      render: ({
        basis_document,
        id,
      }: {
        basis_document: IDocument;
        id?: string;
      }) =>
        basis_document ? (
          <DocumentLink
            url={basis_document?.url}
            title={basis_document?.name}
            onDelete={() => onDelete?.(id)}
            hideDelete={!canEditDocument}
            hideLabel={true}
          />
        ) : (
          !isInspector && (
            <DocumentUpload
              accept="application/pdf"
              onUpload={(file) => onUpload?.(id, file)}
              name={id}
            />
          )
        ),
    },
  ];
};
export const filterFields = (organizations?: DropdownData[]) => {
  return [
    {
      controlType: Input,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Naziv lovišta",
        name: "name",
        placeholder: "Pretraži po nazivu lovišta",
        value: "",
        focus: true,
      },
    },
    {
      controlType: DropdownSearch,
      className: "md:col-span-3 col-span-12",
      options: {
        label: "Korisnik lovišta/Agencija",
        name: "organization_id",
        placeholder: "Izaberi korisnika lovišta/agenciju",
        options: organizations ?? [],
        value: "",
      },
    },
  ];
};

export const filterQueries = {
  name: "",
  organization_id: "",
};

export const huntingGroundsFields = (organizations?: DropdownData[]) => {
  return [
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Naziv lovišta",
        name: "name",
        placeholder: "Naziv lovišta",
        value: "",
        required: true,
      },
    },

    {
      controlType: DropdownSearch,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Korisnik lovišta",
        name: "organization_id",
        placeholder: "Izaberite korinika lovišta",
        value: "",
        options: organizations,
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Površina lovišta",
        name: "surface_area",
        placeholder: "Površina lovišta",
        value: "",
        required: true,
        inputtype: "number",
        unit: "km²",
      },
    },
  ];
};

import * as Yup from "yup";
import { AnimalHuntingPlanRecord } from "../types/Animals";
import { AnimalInputType } from "../enums/animals";

const MAX_HUNTED_NUMBER = 999999;

export const HuntingPlanRecordTotal = (
  huntingPlanRecord?: AnimalHuntingPlanRecord,
) =>
  Yup.object().shape({
    total: Yup.number()
      .typeError("Ukupno mora da bude broj")
      .required("Unesite ukupan broj")
      .max(
        huntingPlanRecord?.total_planned ?? MAX_HUNTED_NUMBER,
        (validator) => `Broj mora da bude manje ili jednak ${validator.max}`,
      ),
  });

export const HuntingPlanRecordPartial = (
  huntingPlanRecord?: AnimalHuntingPlanRecord,
) =>
  Yup.object().shape({
    male: Yup.number()
      .typeError("Mužjak mora da bude broj")
      .required("Unesite broj mužjaka")
      .max(
        huntingPlanRecord?.male_planned ?? MAX_HUNTED_NUMBER,
        (validator) => `Broj mora da bude manje ili jednak ${validator.max}`,
      ),
    female: Yup.number()
      .typeError("Ženka mora da bude broj")
      .required("Unesite broj ženki")
      .max(
        huntingPlanRecord?.female_planned ?? MAX_HUNTED_NUMBER,
        (validator) => `Broj mora da bude manje ili jednak ${validator.max}`,
      ),
    offspring: Yup.number()
      .typeError("Podmladak mora da bude broj")
      .required("Unesite broj podmladaka")
      .max(
        huntingPlanRecord?.offspring_planned ?? MAX_HUNTED_NUMBER,
        (validator) => `Broj mora da bude manje ili jednak ${validator.max}`,
      ),
  });

export const HuntingPlanRecord = (
  huntingPlanRecord?: AnimalHuntingPlanRecord,
) => {
  return huntingPlanRecord?.animal?.input_type === AnimalInputType?.Partial
    ? HuntingPlanRecordPartial(huntingPlanRecord)
    : HuntingPlanRecordTotal(huntingPlanRecord);
};

import config from "../config/config";
import { axios } from "../config/axios";
import { HuntingGround } from "../types/HuntingGrounds";
import { AnimalData, AnimalReportData } from "../types/Animals";
import QueryUtils from "../utils/QueryUtils";

export const HuntingGroundsService = {
  async all(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds?${query}`,
    );

    return response;
  },
  async all_dropdown(organization_ids?: string, association_ids?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds/all?organization_id=${organization_ids ?? ""}&association_id=${association_ids ?? ""}`,
    );

    return response;
  },
  async one(id?: string) {
    const response = await axios.get(`${config.baseUrl}/hunting-grounds/${id}`);

    return response;
  },
  async create(data: HuntingGround) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-grounds`,
      data,
    );

    return response;
  },
  async update(id?: string, data?: HuntingGround) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-grounds/${id}`,
      data,
    );

    return response;
  },

  async delete(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/hunting-grounds/${id}`,
    );

    return response;
  },
  async deleteBasis(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/hunting-grounds/${id}/delete-basis`,
    );

    return response;
  },
  async addBasis(id?: string, data?: FormData) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-grounds/${id}/add-basis`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  },

  async getHuntingPlan(query: Record<string, any>) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds/animal-hunting-plans${QueryUtils.getQueryFilterString(query)}&sort=id&order=asc`,
    );

    return response;
  },

  async createHuntingPlan(id?: string, data?: AnimalData[]) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-grounds/${id}/animal-hunting-plans`,
      { animal_plans: data },
    );

    return response;
  },

  async getRevisions(id: string = "", season: string = "") {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds/${id}/revisions?season=${season}`,
    );

    return response;
  },

  async getCompensationForm(
    id?: string,
    revision_number?: string | number,
    season?: string,
  ) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds/${id}/compensation-forms/pdf?revision_number=${revision_number}&season=${season}`,
    );

    return response;
  },

  async getHuntingPlanRecord(query: Record<string, string>) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-grounds/animal-hunting-plan-realizations${QueryUtils.getQueryFilterString(query)}`,
    );

    return response;
  },

  async createHuntingPlanRecord(
    id?: string,
    data?: {
      season: string;
      month: string;
      animal_plan_realizations: AnimalReportData[];
    },
  ) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-grounds/${id}/animal-hunting-plan-realizations`,
      data,
    );

    return response;
  },

  async updateHuntingPlanRecord(
    id?: string,
    data?: {
      male?: number;
      female?: number;
      offspring?: number;
      total?: number;
      animal_id?: number;
    },
  ) {
    const response = await axios.patch(
      `${config.baseUrl}/animal-hunting-plan-realizations/${id}`,
      data,
    );

    return response;
  },
};

import ROLES from "../enums/roles";
import HuntingCardOrdersList from "@/pages/cms/hunting/hunting-card-orders/HuntingCardOrdersList";
import HuntingCardOrdersAdminEdit from "@/pages/cms/hunting/hunting-card-orders/HuntingCardOrdersAdminEdit";
import Home from "@/pages/cms/Home";
import HuntingCardOrdersAdd from "@/pages/cms/hunting/hunting-card-orders/HuntingCardOrdersAdd";
import HuntingCardOrdersEdit from "@/pages/cms/hunting/hunting-card-orders/HuntingCardOrdersEdit";
import HuntingCardsList from "@/pages/cms/hunting/hunting-card/HuntingCardsList";
import UsersList from "@/pages/cms/users/UsersList";
import UsersAdd from "@/pages/cms/users/UsersAdd";
import UsersEdit from "@/pages/cms/users/UsersEdit";
import OrganizationsList from "@/pages/cms/organizations/OrganizationsList";
import OrganizationsAdd from "@/pages/cms/organizations/OrganizationsAdd";
import OrganizationsEdit from "@/pages/cms/organizations/OrganizationsEdit";
import HuntingGroundsList from "@/pages/cms/hunting-grounds/HuntingGroundsList";
import HuntingGroundsAdd from "@/pages/cms/hunting-grounds/HuntingGroundsAdd";
import HuntingGroundsEdit from "@/pages/cms/hunting-grounds/HuntingGroundsEdit";
import HuntingPlanList from "@/pages/cms/hunting-plan/HuntingPlanList";
import StampOrdersList from "@/pages/cms/stamp-orders/StampOrdersList";
import StampOrdersEdit from "@/pages/cms/stamp-orders/StampOrdersEdit";
import HuntingPlanRecordsList from "@/pages/cms/hunting-plan-records/HuntingPlanRecordsList";
import WarehouseStampsList from "@/pages/cms/warehouse/stamps/WarehouseStampsList";
import WarehouseHuntingCardsList from "@/pages/cms/warehouse/hunting-cards/WarehouseHuntingCardsList";
import HuntingPlanRevisionList from "@/pages/cms/hunting-plan/HuntingPlanRevisionList";
import HuntingCardReportsList from "@/pages/cms/reports/hunting-cards/HuntingCardReportsList";
import HuntingGroundsReportsList from "@/pages/cms/reports/hunting-grounds/HuntingGroundsReportsList";
import CompensationsList from "@/pages/cms/reports/compensations/CompensationsList";
import ExamsList from "@/pages/cms/exams/ExamsList";
import ExamsAdd from "@/pages/cms/exams/ExamsAdd";
import ExamsEdit from "@/pages/cms/exams/ExamsEdit";
import ProfileEdit from "@/pages/cms/profile/ProfileEdit";
import ExamsDocumentsList from "@/pages/cms/exams/ExamsDocumentsList";
import ShootingStatisticsList from "@/pages/cms/reports/shooting-statistics/ShootingStatisticsList";
import ActivityLogsList from "@/pages/cms/activity-logs/ActivityLogsList";
import ActivityLogEdit from "@/pages/cms/activity-logs/ActivityLogsEdit";

const ROUTES = [
  {
    path: "/home",
    allowedRoles: ROLES.all(),
    component: <Home />,
  },
  // Hunting Cards
  {
    path: "/hunting-cards",
    allowedRoles: ROLES.all(),
    component: <HuntingCardsList />,
  },
  // Hunting Card Orders
  {
    path: "/hunting-card-orders",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Agency,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <HuntingCardOrdersList />,
  },
  {
    path: "/hunting-card-orders/:id",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Agency,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <HuntingCardOrdersAdminEdit />,
  },
  {
    path: "/hunting-card-orders/create",
    allowedRoles: [ROLES.User, ROLES.Agency],
    component: <HuntingCardOrdersAdd />,
  },
  {
    path: "/hunting-card-orders/create/:id",
    allowedRoles: [ROLES.User, ROLES.Agency],
    component: <HuntingCardOrdersEdit />,
  },
  // Users
  {
    path: "/users",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Inspector,
      ROLES.Moderator,
    ],
    component: <UsersList />,
  },
  {
    path: "/users/create",
    allowedRoles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
    component: <UsersAdd />,
  },
  {
    path: "/users/:id",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Inspector,
      ROLES.Moderator,
    ],
    component: <UsersEdit />,
  },
  // Organizations
  {
    path: "/organizations",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Inspector,
      ROLES.Moderator,
    ],
    component: <OrganizationsList />,
  },
  {
    path: "/organizations/create",
    allowedRoles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
    component: <OrganizationsAdd />,
  },
  {
    path: "/organizations/:id",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Inspector,
      ROLES.Moderator,
    ],
    component: <OrganizationsEdit />,
  },
  // Hunting Grounds
  {
    path: "/hunting-grounds",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Inspector,
      ROLES.Ranger,
      ROLES.Moderator,
    ],
    component: <HuntingGroundsList />,
  },
  {
    path: "/hunting-grounds/create",
    allowedRoles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
    component: <HuntingGroundsAdd />,
  },
  {
    path: "/hunting-grounds/:id",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Inspector,
      ROLES.Ranger,
      ROLES.Moderator,
    ],
    component: <HuntingGroundsEdit />,
  },
  // Hunting Plan
  {
    path: "/hunting-plan",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Inspector,
      ROLES.Ranger,
      ROLES.Moderator,
    ],
    component: <HuntingPlanList />,
  },
  {
    path: "/hunting-plan/revision/:id",
    allowedRoles: [ROLES.User],
    component: <HuntingPlanRevisionList />,
  },
  // Hunting Plan Records
  {
    path: "/hunting-records",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <HuntingPlanRecordsList />,
  },
  // Stamp Orders
  {
    path: "/stamp-orders",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <StampOrdersList />,
  },
  // Stamp Orders
  {
    path: "/stamp-orders/:id",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <StampOrdersEdit />,
  },
  // Warehouse
  {
    path: "/warehouse-stamps",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <WarehouseStampsList />,
  },
  {
    path: "/warehouse-hunting-cards",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <WarehouseHuntingCardsList />,
  },
  // Reports
  {
    path: "/reports/hunting-card",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Moderator,
      ROLES.Inspector,
    ],
    component: <HuntingCardReportsList />,
  },
  {
    path: "/reports/hunting-ground",
    allowedRoles: [ROLES.Superadmin, ROLES.Moderator, ROLES.Inspector],
    component: <HuntingGroundsReportsList />,
  },
  {
    path: "/reports/shooting-statistics",
    allowedRoles: [ROLES.Superadmin, ROLES.Moderator, ROLES.Inspector],
    component: <ShootingStatisticsList />,
  },
  {
    path: "/reports/compensations",
    allowedRoles: [ROLES.Superadmin, ROLES.Moderator, ROLES.Inspector],
    component: <CompensationsList />,
  },
  // Exams
  {
    path: "/exams",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.Moderator,
      ROLES.Ranger,
      ROLES.Inspector,
      ROLES.Examiner,
    ],
    component: <ExamsList />,
  },
  {
    path: "/exams/create",
    allowedRoles: ROLES.all(),
    component: <ExamsAdd />,
  },
  {
    path: "/exams/:id",
    allowedRoles: ROLES.all(),
    component: <ExamsEdit />,
  },
  {
    path: "/exams/documents",
    allowedRoles: [ROLES.Superadmin, ROLES.Moderator],
    component: <ExamsDocumentsList />,
  },
  // Profile
  {
    path: "/profile",
    allowedRoles: [
      ROLES.Superadmin,
      ROLES.Admin,
      ROLES.User,
      ROLES.Agency,
      ROLES.Ranger,
      ROLES.Moderator,
      ROLES.Examiner,
      ROLES.Inspector,
    ],
    component: <ProfileEdit />,
  },
  // Activity Logs
  {
    path: "/activity-logs",
    allowedRoles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
    component: <ActivityLogsList />,
  },
  {
    path: "/activity-logs/:id",
    allowedRoles: [ROLES.Superadmin, ROLES.Admin, ROLES.Moderator],
    component: <ActivityLogEdit />,
  },
];

export default ROUTES;

import { RefObject } from "react";
import FormFactory from "../forms/FormFactory";
import { FormikProps } from "formik";
import TooltipWrapper from "../TooltipWrapper";
import { Button } from "../ui/button";
import { GrSearch } from "react-icons/gr";

type TableFilterFormProps = {
  showFilters: boolean;
  filterFields: any;
  onSubmit: (values: any) => void;
  formRef: RefObject<FormikProps<any>>;
};

const TableFilterForm = ({
  showFilters,
  filterFields,
  onSubmit,
  formRef,
}: TableFilterFormProps) => {
  return showFilters ? (
    <>
      {filterFields && (
        <div className="flex items-center justify-between space-x-4 rounded-lg bg-white p-3">
          <div className="flex-1">
            <FormFactory
              formFields={filterFields}
              onSubmit={onSubmit}
              className="grid grid-cols-12 gap-4"
              formRef={formRef}
              autoSubmit={true}
            />
          </div>
          <TooltipWrapper
            text="Filtriraj"
            button={
              <Button
                type="button"
                variant="secondary"
                className={`${filterFields?.length > 4 ? "mt-6 self-start" : "self-end"}`}
                onClick={() => {
                  formRef?.current?.handleSubmit?.();
                }}
              >
                <GrSearch size={15} />
              </Button>
            }
          />
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

export default TableFilterForm;

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../ui/dropdown";
import InputRequiredTooltip from "./InputRequiredTooltip";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { FieldInputProps, FieldProps, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { DropdownData, FilterableDropdownData } from "@/common/types/Form";
import { GoSearch } from "react-icons/go";
import { Value } from "@radix-ui/react-select";

type MultipleSelectDropdownProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  countablePlural?: string;
  searchablePlural?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  options: DropdownData[] | FilterableDropdownData[];
  value: string[];
  className?: string;
  onChange: () => void;
  submit?: () => void;
};

type MultipleSelectFormProps = FieldProps<any> & MultipleSelectDropdownProps;

const MultiSelectDropdown = ({
  field,
  form,
  label,
  countablePlural,
  searchablePlural,
  disabled,
  required,
  placeholder,
  options,
  className,
  onChange,
  submit,
}: MultipleSelectFormProps) => {
  const [selectedValues, setSelectedValues] = useState<any[]>([]);
  const [oldSelectedValues, setOldSelectedValues] = useState<any[]>([]);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [displayText, setDisplayText] = useState("");
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState<any[]>(options);
  const [allOptions, setAllOptions] = useState<any[]>(options);

  const searchRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLDivElement | null>(null);

  const error = form?.errors?.[field?.name!];

  const emptySearchEvent = {
    target: { value: "" },
    preventDefault: () => {},
  };

  const updateFieldValue = (newValues?: any[]) => {
    form?.setFieldValue(field?.name, newValues ?? selectedValues);
    onChange?.();
  };

  const handleSelectChange = (value: number | string) => {
    const currentIndex = selectedValues.indexOf(value);
    const newSelectedValues = [...selectedValues];

    if (currentIndex === -1) {
      newSelectedValues.push(value);
    } else {
      newSelectedValues.splice(currentIndex, 1);
    }

    setSelectedValues(newSelectedValues);
    setIsDirty(true);
  };

  const handleDatasetChange = () => {
    if (options?.length === allOptions?.length) {
      onSearch(emptySearchEvent);
      return;
    }

    const newOptions = onSearch(emptySearchEvent);

    const newValues = selectedValues?.filter((value: any) =>
      newOptions.some(
        (option: DropdownData | FilterableDropdownData) => option.id === value,
      ),
    );

    if (
      newValues?.every((value) => selectedValues?.includes(value)) &&
      newValues?.length === selectedValues?.length
    ) {
      return;
    }

    setSelectedValues(newValues);
    updateFieldValue(newValues);
  };

  const isOptionSelected = (value: number | string): boolean => {
    if (!Boolean(selectedValues?.length)) {
      return false;
    }

    return selectedValues?.includes(value) ? true : false;
  };

  function formatSelectedItemsString(): void {
    if (!Boolean(selectedValues?.length)) {
      setDisplayText("");
      return;
    }
    const labels: string[] = [];

    for (const value of selectedValues) {
      for (const obj of allOptions) {
        if (obj?.id === value) {
          labels.push(obj?.name);
        }
      }
    }

    const selectedItemsString =
      labels?.length >= 3
        ? `${labels?.length} izabranih ${countablePlural ?? "opcija"}`
        : labels.join(", ");

    setDisplayText(selectedItemsString);
  }

  const onSearch = (e?: any): DropdownData[] | FilterableDropdownData[] => {
    e?.preventDefault();
    const searchValue = e?.target?.value?.toLowerCase() ?? search;
    setSearch(searchValue);

    const filtered = allOptions?.filter(
      (option: DropdownData | FilterableDropdownData) => {
        const isInOptions = options?.some((o) => option?.id === o?.id);
        const matchesSearch = option?.name
          ?.toLowerCase()
          ?.includes(searchValue);

        return matchesSearch && isInOptions;
      },
    );

    setFilteredOptions(filtered);

    return filtered;
  };

  const onKeyPress = () => {
    if (searchRef?.current) {
      searchRef.current?.focus();
    }

    if (optionsRef?.current) {
      optionsRef.current.scrollTo();
    }
  };

  const handleDropdownClose = (e: any) => {
    e.preventDefault();
    onSearch(emptySearchEvent);

    if (
      oldSelectedValues?.every((value) => selectedValues?.includes(value)) &&
      oldSelectedValues?.length === selectedValues?.length
    ) {
      setIsDirty(false);
      return;
    }

    if (!isDirty) {
      return;
    }

    setIsDirty(false);
    setOldSelectedValues(selectedValues);
    updateFieldValue();
  };

  useEffect(() => {
    setAllOptions((prev) => (options?.length > prev?.length ? options : prev));
  }, [options]);

  useEffect(() => {
    handleDatasetChange();
    // eslint-disable-next-line
  }, [allOptions, options]);

  useEffect(() => {
    if (!field?.value?.length) {
      setSelectedValues([]);
    }
    formatSelectedItemsString();
    submit?.();
    // eslint-disable-next-line
  }, [field?.value]);

  useEffect(() => {
    formatSelectedItemsString();
    // eslint-disable-next-line
  }, [selectedValues]);

  return (
    <div
      className={`${className} relative ${disabled ? "opacity-50" : ""}`}
      onKeyDownCapture={onKeyPress}
    >
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={disabled}>
          <div className="relative">
            {label && (
              <div className="flex flex-row items-center space-x-1">
                <label className="mb-2 block text-xs text-label">{label}</label>
                {required && <InputRequiredTooltip label="Polje je obavezno" />}
              </div>
            )}
            <div className="absolute bottom-3 right-3">
              <CaretSortIcon className="h-4 w-4 opacity-50" />
            </div>
            <input
              disabled={true}
              value={displayText}
              className={`w-full cursor-pointer rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label placeholder:text-black focus:border-primary ${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""}`}
              placeholder={placeholder}
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          onCloseAutoFocus={handleDropdownClose}
          className="relative max-h-80"
          hidden={disabled}
        >
          <div className="sticky top-0 z-10 flex h-10 flex-row items-center justify-stretch border-b border-gray-300 bg-inherit">
            <GoSearch className="absolute left-3 top-3 mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              ref={searchRef}
              type="text"
              value={search}
              placeholder={`Pretraži ${searchablePlural ?? "opcije"}`}
              className="z-10 w-full rounded-none bg-transparent p-2 ps-8 text-xs"
              onChange={onSearch}
              autoComplete="off"
            />
          </div>
          <div
            ref={optionsRef}
            className="max-h-[17.5rem] overflow-y-scroll rounded-b-sm"
          >
            {filteredOptions?.map(
              (
                value: MultipleSelectDropdownProps["options"][0],
                index: number,
              ) => {
                return (
                  <DropdownMenuCheckboxItem
                    onSelect={(e) => e.preventDefault()}
                    key={index}
                    checked={isOptionSelected(value.id)}
                    onCheckedChange={() => handleSelectChange(value.id)}
                    className="focus:bg-inherit"
                  >
                    {value.name}
                  </DropdownMenuCheckboxItem>
                );
              },
            )}
          </div>
        </DropdownMenuContent>
      </DropdownMenu>
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default MultiSelectDropdown;

import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "../ui/utils";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { useState } from "react";
import { FieldInputProps, FormikProps } from "formik";
import InputRequiredTooltip from "./InputRequiredTooltip";

type DropdownProps = {
  field?: FieldInputProps<any>;
  form?: FormikProps<any>;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  className?: string;
  options: {
    name: string;
    id: number;
  }[];
  value?: string;
  submit?: () => void;
};

const DropdownSearch = ({
  form,
  field,
  className,
  label,
  options,
  required,
  placeholder,
  disabled,
  submit,
}: DropdownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const error = form?.errors?.[field?.name!];

  return (
    <div className={`${className}`}>
      {label && (
        <div className="flex flex-row items-center space-x-1">
          <label className="mb-2 block text-xs text-label">{label}</label>
          {required && <InputRequiredTooltip label="Polje je obavezno" />}
        </div>
      )}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <button
            disabled={disabled}
            className={` relative ${error && !disabled ? "border-red-500 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500" : ""} flex w-full cursor-pointer items-center justify-between truncate whitespace-nowrap rounded-lg border border-gray-300 bg-background p-2.5 text-xs text-label placeholder:text-placeholder focus:border-primary focus:outline-none disabled:pointer-events-none disabled:opacity-50 [&>span]:line-clamp-1`}
          >
            {field?.value
              ? options.find((option) => String(option.id) === field?.value)
                  ?.name ?? placeholder
              : placeholder}
            <CaretSortIcon className="absolute right-2 h-4 w-4 shrink-0 opacity-50" />
          </button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Command onChange={(e) => e?.stopPropagation()}>
            <CommandInput disabled={disabled} placeholder={placeholder} />
            <CommandList>
              <CommandEmpty>Nema rezultata.</CommandEmpty>
              <CommandGroup>
                {options.map((option, index) => (
                  <CommandItem
                    disabled={disabled}
                    key={index}
                    value={`${option.id},${option.name}`}
                    onSelect={(currentValue: string) => {
                      form?.setFieldValue(
                        field?.name!,
                        currentValue === String(field?.value)
                          ? ""
                          : currentValue.split(",")[0],
                      );
                      setOpen(false);
                      submit?.();
                    }}
                  >
                    <CheckIcon
                      className={cn(
                        "mr-2 h-4 w-4",
                        field?.value === String(option.id)
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {option.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {disabled ? null : (
        <p className="absolute text-xs text-error">
          {error ? (error as string) : ""}
        </p>
      )}
    </div>
  );
};

export default DropdownSearch;

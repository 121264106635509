import { useAuth } from "@/common/context/AuthProvider";
import { IUser, UserData } from "@/common/types/User";
import FormFactory from "@/components/forms/FormFactory";
import PageLayout from "@/components/layout/PageLayout";
import { FormikProps } from "formik";
import { useState, useEffect, useRef } from "react";
import { profileFields } from "./profile-fields";
import ROLES from "@/common/enums/roles";
import { ProfileService } from "@/common/API/ProfileService";

const ProfileEdit = () => {
  const { loginData } = useAuth();
  const [userData, setUserData] = useState<IUser | undefined>(loginData?.data);
  const formRef = useRef<FormikProps<any>>(null);

  const onSubmit = async (values: UserData) => {};

  const getProfileInformation = async () => {
    const response = await ProfileService.get();
    const userData: IUser = response?.data?.data;

    setUserData(userData);

    return userData;
  };

  useEffect(() => {
    const getUserData = async () => {
      const fetchedData = await getProfileInformation();

      formRef?.current?.setValues({
        ...fetchedData,
        role_id: fetchedData?.roles?.find((role) => role?.id !== ROLES.Examiner)
          ?.name,
        association_id: fetchedData?.association?.name,
        organization_id: fetchedData?.organization?.name,
        password: "",
        password_confirmation: "",
        is_examiner:
          fetchedData?.roles?.filter((role) => role?.id === ROLES.Examiner)
            ?.length !== 0,
      });
    };

    getUserData();
  }, []);

  return (
    <PageLayout title="Profil" backUrl="./../">
      <FormFactory
        formFields={profileFields(
          userData?.roles?.find((role) => role?.id !== ROLES.Examiner)?.id,
        )}
        onSubmit={onSubmit}
        formRef={formRef}
      />
    </PageLayout>
  );
};

export default ProfileEdit;

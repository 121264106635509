import { useEffect, useRef } from "react";
import Text from "../Text";
type InputProps = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (name: string, val: number) => void;
};

const AnimalInput = ({
  name,
  placeholder,
  label,
  disabled,
  className,
  onChange,
}: InputProps) => {
  const inputRef = useRef(null);

  const handleChange = (el: React.ChangeEvent<HTMLInputElement>) => {
    const value = el.target.value;
    if (value.length > 6) {
      el.target.value = value.slice(0, 6);
    }
    onChange?.(name, Number(el.target.value));
  };

  const handleWheel = (e: WheelEvent) => {
    const input = e?.target as HTMLInputElement;

    input.setAttribute("readonly", "true");

    setTimeout(() => {
      input.removeAttribute("readonly");
    }, 0);
  };

  useEffect(() => {
    if (inputRef?.current) {
      const input = inputRef?.current as HTMLInputElement;

      input?.addEventListener("wheel", handleWheel, {
        passive: true,
      });
    }
  }, []);

  return (
    <div className={`${className} relative`}>
      {disabled ? (
        <Text variant="table">-</Text>
      ) : (
        <>
          <label className="mb-2 block text-xs text-label">{label}</label>
          <input
            ref={inputRef}
            type="number"
            disabled={disabled}
            className={`w-full rounded-lg border border-gray-300 bg-background px-1.5 py-2.5 text-xs text-label  focus:border-primary disabled:pointer-events-none disabled:opacity-50`}
            placeholder={placeholder}
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
};

export default AnimalInput;

import config from "../config/config";
import { axios } from "../config/axios";
import { HunterData } from "../types/HuntingOrders";

export const HuntingCardOrdersService = {
  async all(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-card-orders?${query}`,
    );
    return response;
  },

  async one(id?: string, query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-card-orders/${id}?${query}`,
    );

    return response;
  },

  async return({ id, admin_note }: { id?: string; admin_note?: string }) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${id}/return`,
      {
        admin_note,
      },
    );

    return response;
  },

  async accept(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${id}/accept`,
    );

    return response;
  },

  async create(data: FormData) {
    const response = await axios.post(
      `${config.baseUrl}/hunting-card-orders`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return response;
  },

  async update(id?: string, data?: FormData) {
    const resposne = await axios.post(
      `${config.baseUrl}/hunting-card-orders/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );

    return resposne;
  },

  async createHunter(
    id: string | undefined,
    data: {
      name: string;
      citizenship: string;
      unique_identifier: string;
      residence: string;
      address: string;
      gender: string;
      hunting_card_type: string | number;
      in_organization: boolean;
    },
  ) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${id}/add-hunter`,
      data,
    );

    return response;
  },

  async importHunters(id: string | undefined) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-card-orders/${id}/import-previous-season-hunters`,
    );

    return response;
  },

  async updateHunter(
    huntingCardId?: string,
    hunterId?: number | string,
    data?: HunterData,
    withAddressAndResidence: boolean = false,
  ) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${huntingCardId}/update-hunter/${hunterId}`,
      Object.fromEntries(
        Object.entries({
          id: data?.id,
          name: data?.name,
          citizenship: data?.citizenship,
          unique_identifier: data?.unique_identifier,
          address: data?.address,
          residence: data?.residence,
          gender: data?.gender,
          hunting_card_type: data?.hunting_card_type,
          in_organization: data?.in_organization,
        }).filter((attr) => attr),
      ),
    );

    return response;
  },

  async deleteHunter(huntingCardId?: string, hunterId?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${huntingCardId}/remove-hunter/${hunterId}`,
    );

    return response;
  },

  async getHunterPdf(cardId: string | undefined, hunterId: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-card-orders/${cardId}/hunters/${hunterId}/pdf`,
    );

    return response;
  },

  async send(id?: string) {
    const response = await axios.patch(
      `${config.baseUrl}/hunting-card-orders/${id}/send`,
    );

    return response;
  },

  async delete(id?: string) {
    const response = await axios.delete(
      `${config.baseUrl}/hunting-card-orders/${id}`,
    );

    return response;
  },

  async getAllHunterPdf(huntingCardId?: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-card-orders/${huntingCardId}/pdf`,
    );

    return response;
  },
};

import CONSTANTS from "@/common/config/constants";
import { HuntingCardType } from "@/common/enums/hunting";
import { DropdownData } from "@/common/types/Form";
import { IAssociation } from "@/common/types/HuntingOrders";
import { Organization } from "@/common/types/Reports";
import { WarehouseHuntingCard } from "@/common/types/Warehouse";
import Utils from "@/common/utils/Utils";
import Dropdown from "@/components/forms/Dropdown";
import Input from "@/components/forms/Input";
import Tag from "@/components/Tag";
import Text from "@/components/Text";
import TooltipWrapper from "@/components/TooltipWrapper";

export const tableColumns = [
  {
    title: "Savez/Agencija",
    dataIndex: ["association", "name"],
    key: ["association", "name"],
    render: ({
      association,
      organization,
    }: {
      association: IAssociation;
      organization: Organization;
    }) => {
      const a = CONSTANTS.huntingAssociations.find(
        (a) => a?.id === association?.id,
      );

      return association ? (
        <TooltipWrapper
          button={
            <div className="flex flex-row items-center space-x-1">
              <Tag title={a?.name} className={a?.className} />
            </div>
          }
          text={a?.fullName!}
        />
      ) : (
        <Text variant="table">{organization?.name}</Text>
      );
    },
  },
  {
    title: "Tip",
    dataIndex: "card_type",
    key: "card_type",
    render: ({ card_type }: { card_type: number }) => (
      <Text variant="table">
        {card_type === HuntingCardType.Tourist ? "Turističke" : "Članske"}
      </Text>
    ),
  },
  {
    title: "Od broja",
    dataIndex: "from_number",
    key: "from_number",
  },
  {
    title: "Do broja",
    dataIndex: "to_number",
    key: "to_number",
  },
  {
    title: "Raspoloživo",
    dataIndex: "available_cards",
    key: "available_cards",
    render: ({ available_cards }: { available_cards: number }) => (
      <Text variant="table">{available_cards < 0 ? 0 : available_cards}</Text>
    ),
  },
  {
    title: "Sezona",
    dataIndex: "season",
    key: "season",
  },
  {
    title: "Datum dodavanja",
    dataIndex: "created_at",
    key: "created_at",
    render: ({ created_at }: { created_at: string }) => (
      <Text variant="table">{Utils.convertTime(created_at)}</Text>
    ),
  },
];

export const filterFields = (
  associations?: DropdownData[],
  organizations?: DropdownData[],
  query?: Record<string, string>,
) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Lovački savez",
        name: "association_id",
        placeholder: "Izaberite lovački savez",
        value: "",
        required: false,
        options: associations ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6 lg:col-span-3",
      options: {
        label: "Agencije i preduzeća",
        name: "organization_id",
        placeholder: "Izaberite agenciju ili preduzeće",
        value: "",
        required: false,
        hide:
          !(Number(query?.association_id) === -1) ||
          organizations?.length === 0,
        options: organizations ?? [],
      },
    },
  ];
};

export const filterQueries = {
  association_id: "",
};

export const warehouseHuntingCardsAddFields = (
  associations?: DropdownData[],
  organizations?: DropdownData[],
  lastAvailableCardNumber?: number,
  query?: Record<string, string>,
  data?: WarehouseHuntingCard,
) => {
  return [
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Lovački savez/Agencija",
        name: "association_id",
        placeholder: "Izaberite lovački savez",
        value: query?.association_id ?? "",
        required: true,
        hide: Number(query?.association_id) === -1,
        options: associations ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Agencije i preduzeća",
        name: "organization_id",
        placeholder: "Izaberite agenciju ili preduzeće",
        value: query?.organization_id ?? "",
        required: true,
        hide: !(Number(query?.association_id) === -1),
        options: organizations ?? [],
      },
    },
    {
      controlType: Dropdown,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Tip",
        name: "card_type",
        placeholder: "Izaberite tip lovne karte",
        value: data?.card_type ? String(data?.card_type) : "",
        required: true,
        options: [
          { name: "Turistička", id: HuntingCardType.Tourist },
          { name: "Članska", id: HuntingCardType.Member },
        ],
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: `Od broja - ${data?.from_number ?? lastAvailableCardNumber}`,
        name: "from_number",
        placeholder: `Od broja`,
        value: data?.from_number ?? "",
        required: true,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: `Do broja${data?.to_number ? `- ${data?.to_number}` : ""}`,
        name: "to_number",
        placeholder: "Do broja",
        value: data?.to_number ?? "",
        required: true,
      },
    },
  ];
};

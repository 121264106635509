import config from "../config/config";
import { axios } from "../config/axios";

export const HuntingCardService = {
  async all(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-cards?${query}`,
    );

    return response;
  },

  async pdf(huntingCardId: number | string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-cards/${huntingCardId}/pdf`,
    );

    return response;
  },

  async export(query: string) {
    const response = await axios.get(
      `${config.baseUrl}/hunting-cards/export?${query}`,
    );

    return response;
  },
};

import Table from "@/components/table/Table";
import { useEffect, useState } from "react";
import {
  tableColumns,
  filterFields,
  filterQueries,
} from "./compensation-fields";
import PageLayout from "@/components/layout/PageLayout";
import { useQuery } from "@/common/hooks/useQuery";
import Utils from "@/common/utils/Utils";
import { ReportsService } from "@/common/API/ReportsService";
import { CompensationsData } from "@/common/types/Reports";
import { DropdownData } from "@/common/types/Form";
import Card from "@/components/Card";

const CompensationsList = () => {
  const [data, setData] = useState<CompensationsData>();
  const [seasons, setSeasons] = useState<DropdownData[]>();
  const [isFetching, setIsFetching] = useState(false);
  const { query, setQuery, setFilters } = useQuery({
    hasPagination: false,
    filterQueries,
  });

  const getCompensations = async () => {
    try {
      setIsFetching(true);
      const response = await ReportsService.all_compensations(
        Utils.formatQueryString(query),
      );

      const availableSeasons: DropdownData[] =
        response?.data?.data?.available_seasons.map((season: string) => ({
          name: season,
          id: season,
        }));

      if (query?.season && query?.season === "") {
        const season = availableSeasons?.[0]?.id ?? "";

        setQuery((prevState: Record<string, string>) => ({
          ...prevState,
          season: prevState?.season || String(season),
        }));
      }

      setSeasons(availableSeasons);
      setData(response?.data?.data?.data);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getCompensations();
  }, [query]);

  return (
    <PageLayout title="Tabela naknade">
      <Table
        data={{ data: data?.compensations }}
        columns={tableColumns}
        filterFields={filterFields(seasons)}
        filterQueries={filterQueries}
        tableHeaderContent={
          <div className=" my-4 items-center space-y-4 md:flex md:flex-row md:space-x-4 md:space-y-0">
            <Card
              title="Ukupno za uplatu"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {Utils.formatNumber(data?.total_price)} rsd.
                  </h2>
                </div>
              }
              isLoading={!data}
            />
            <Card
              title="Za uplatu do 15.04"
              content={
                <div>
                  <h2 className="text-xl font-medium">
                    {Utils.formatNumber(data?.calculated_price)} rsd.
                  </h2>
                </div>
              }
              isLoading={!data}
            />
          </div>
        }
        hideActions={true}
        query={query}
        setQuery={setQuery}
        setFilters={setFilters}
        isFetching={isFetching}
      />
    </PageLayout>
  );
};

export default CompensationsList;

import config from "../config/config";
import { axios } from "../config/axios";

export const ActivityLogsService = {
  async all(query?: string) {
    const response = await axios.get(
      `${config.baseUrl}/activity-logs?${query}`,
    );

    return response;
  },

  async one(id?: number | string) {
    const response = await axios.get(`${config.baseUrl}/activity-logs/${id}`);

    return response;
  },
};

import { SidebarChild, SidebarItemType } from "@/common/types/Sidebar";
import SidebarChildItem from "./SidebarChildItem";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { useEffect, useRef } from "react";

const SidebarItem = ({ label, route, children }: SidebarItemType) => {
  const matchingChildRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (matchingChildRef.current) {
      matchingChildRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, []);

  return (
    <div>
      {route ? (
        <SidebarChildItem route={route} label={label} />
      ) : (
        <AccordionItem value={label}>
          <AccordionTrigger
            style={{
              backgroundColor: "#070d12",
              color: "#717891",
              borderColor: "#717891",
            }}
          >
            {label}
          </AccordionTrigger>
          <div className="flex flex-col">
            {children?.map((child: SidebarChild, index: number) => {
              const isOpen = child?.route === window.location.pathname;

              return (
                <AccordionContent
                  ref={isOpen ? matchingChildRef : null}
                  key={index}
                  style={{ backgroundColor: "#070d12", color: "#717891" }}
                  // forceMount={isForceMounted}
                >
                  <SidebarChildItem
                    route={child?.route}
                    label={child?.label}
                    icon={child?.icon}
                    key={index}
                  />
                </AccordionContent>
              );
            })}
          </div>
        </AccordionItem>
      )}
    </div>
  );
};

export default SidebarItem;

import { createContext, PropsWithChildren, useContext, useState } from "react";

export interface SidebarContextType {
  openMenus: string[];
  setOpenMenus: (openMenus: string[]) => void;
  resetSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextType | null>(null);

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

export const SidebarProvider = ({ children }: PropsWithChildren) => {
  const [openMenus, setOpenMenus] = useState<string[]>([]);

  const resetSidebar = () => {
    setOpenMenus([]);
  };

  return (
    <SidebarContext.Provider value={{ openMenus, setOpenMenus, resetSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

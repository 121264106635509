import * as Yup from "yup";

export const CreateHuntingCardOrderSchema = Yup.object().shape({
  statement: Yup.mixed(),
  authorization: Yup.mixed(),
  payment_slip: Yup.mixed(),
});

export const CreateHunterSchema = Yup.object().shape({
  name: Yup.string().required("Unesite ime"),
  citizenship: Yup.string().required("Unesite državljanstvo"),
  unique_identifier: Yup.mixed(),
  address: Yup.string().required("Unesite adresu"),
  residence: Yup.string().required("Unesite mesto"),
  gender: Yup.number().required("Izaberite pol"),
  hunting_card_type: Yup.number().required("Izaberite tip lovne karte"),
});

export const UpdateHunterSchema = Yup.object().shape({
  name: Yup.string().required("Unesite ime"),
  citizenship: Yup.string().required("Unesite državljanstvo"),
  unique_identifier: Yup.mixed(),
  address: Yup.string().optional(),
  residence: Yup.string().optional(),
  gender: Yup.number().required("Izaberite pol"),
  hunting_card_type: Yup.number().required("Izaberite tip lovne karte"),
});

import {
  HunterGender,
  HuntingCardOrderStatus,
  HuntingCardType,
} from "@/common/enums/hunting";
import { HunterData, HuntingOrderData } from "@/common/types/HuntingOrders";
import StatusTag from "@/components/StatusTag";
import Text from "@/components/Text";
import Checkbox from "@/components/forms/Checkbox";
import Dropdown from "@/components/forms/Dropdown";
import Input from "@/components/forms/Input";

export const tableColumns = (orderStatus?: number) => {
  return [
    {
      title: "Redni broj",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (data: HuntingOrderData, index: number) => {
        return <Text variant="table">{index + 1}</Text>;
      },
    },
    {
      title: "Ime i prezime",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "JMBG/Broj pasoša",
      dataIndex: "unique_identifier",
      key: "unique_identifier",
    },
    {
      title: "Državljanstvo",
      dataIndex: "citizenship",
      key: "citizenship",
    },
    {
      title: "Mesto",
      dataIndex: "residence",
      key: "residence",
    },
    {
      title: "Adresa",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Pol",
      dataIndex: "gender",
      key: "gender",
      render: ({ gender }: { gender: number }) => (
        <div>{gender === HunterGender.MALE ? "Muško" : "Žensko"}</div>
      ),
    },
    {
      title: "Lovna karta",
      dataIndex: "hunting_card_type",
      key: "hunting_card_type",
      render: ({ hunting_card_type }: { hunting_card_type: number }) => {
        switch (hunting_card_type) {
          case HuntingCardType.Tourist:
            return <StatusTag type="in-progress" title="Turistička" />;
          case HuntingCardType.Member:
            return <StatusTag type="accepted" title="Članska" />;
        }
      },
    },
    {
      title: "Broj lovne karte",
      dataIndex: ["hunting_cards", "card_number"],
      key: ["hunting_cards", "card_number"],
      render: ({
        hunting_cards,
      }: {
        hunting_cards: { id: number; card_number: number }[];
      }) => (
        <Text variant="table">
          {orderStatus === HuntingCardOrderStatus.Accepted
            ? hunting_cards[0]?.card_number ?? "-"
            : "-"}
        </Text>
      ),
    },
  ];
};

const nameInput = (value?: string) => ({
  controlType: Input,
  className: "col-span-12 md:col-span-6",
  options: {
    label: "Ime i prezime",
    name: "name",
    placeholder: "Ime i prezime",
    value: value ?? "",
    required: true,
  },
});

const citizenshipInput = (value?: string) => ({
  controlType: Input,
  className: "col-span-12 md:col-span-6",
  options: {
    label: "Državljanstvo",
    name: "citizenship",
    placeholder: "Državljanstvo",
    value: value ?? "Srpsko",
    required: true,
  },
});

const uniqueIdentifierInput = (value?: string) => ({
  controlType: Input,
  className: "col-span-12 md:col-span-6",
  options: {
    label: "JMBG/Broj pasoša",
    name: "unique_identifier",
    placeholder: "JMBG/Broj pasoša",
    value: value ?? "",
    required: true,
  },
});

const genderDropdown = (value?: number | string) => ({
  controlType: Dropdown,
  className: "col-span-12 md:col-span-6",
  options: {
    label: "Pol",
    name: "gender",
    placeholder: "Izaberite pol",
    value: value ? String(value) : String(HunterGender.MALE),
    required: true,
    options: [
      { name: "Muško", id: HunterGender.MALE },
      { name: "Žensko", id: HunterGender.FEMALE },
    ],
  },
});

const huntingCardTypeDropdown = (value?: number | string) => ({
  controlType: Dropdown,
  className: "col-span-12 md:col-span-6",
  options: {
    label: "Tip lovne karte",
    name: "hunting_card_type",
    placeholder: "Izaberite tip lovne karte",
    value: value ? String(value) : String(HuntingCardType.Member),
    required: true,
    options: [
      { name: "Turistička", id: HuntingCardType.Tourist },
      { name: "Članska", id: HuntingCardType.Member },
    ],
  },
});

const inOrganizationCheckbox = (value?: boolean) => ({
  controlType: Checkbox,
  className: "col-span-12 md:col-span-6 mb-3",
  options: {
    label: "Da li je lovac korisik lovišta?",
    name: "in_organization",
    placeholder: "Da li je lovac korisnik lovišta?",
    value: value ?? false,
    hide: true,
  },
});

export const createHunterFields = (
  hunter?: HunterData,
  showAddressAndResidence: boolean = true,
) => {
  const baseFileds = [
    nameInput(hunter?.name),
    citizenshipInput(hunter?.citizenship),
    uniqueIdentifierInput(hunter?.unique_identifier),
    genderDropdown(hunter?.gender),
    huntingCardTypeDropdown(hunter?.hunting_card_type),
    inOrganizationCheckbox(hunter?.in_organization),
  ];

  const allFields = [
    nameInput(hunter?.name),
    citizenshipInput(hunter?.citizenship),
    uniqueIdentifierInput(hunter?.unique_identifier),
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Adresa",
        name: "address",
        placeholder: "Adresa",
        value: hunter?.address ?? "",
        required: showAddressAndResidence,
        hide: !showAddressAndResidence,
        disabled: !showAddressAndResidence,
      },
    },
    {
      controlType: Input,
      className: "col-span-12 md:col-span-6",
      options: {
        label: "Mesto",
        name: "residence",
        placeholder: "Mesto",
        value: hunter?.residence ?? "",
        required: showAddressAndResidence,
        hide: !showAddressAndResidence,
        disabled: !showAddressAndResidence,
      },
    },
    genderDropdown(hunter?.gender),
    huntingCardTypeDropdown(hunter?.hunting_card_type),
    inOrganizationCheckbox(hunter?.in_organization),
  ];

  return showAddressAndResidence ? allFields : baseFileds;
};
